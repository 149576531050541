<template>
  <div class="u-orderlist">
    <agc-page-header
      :title="$t('ctp.translationServices')"
      :description="$t('ctp.translationDescription')"
    >
      <el-link
        type="primary"
        target="_blank"
        :href="helpLink"
      >
        {{ $t("ctp.helpDocument") }}
      </el-link>
      <span slot="right">
        <el-button type="primary" @click="createTranslateTask">
          {{
            $t("ctp.create")
          }}
        </el-button>
      </span>
    </agc-page-header>
    <div class="orderlist-wrapper">
      <div class="search-condition">
        <div class="search-flex">
          <div class="search-style">
            <span class="search-width">{{ $t("ctp.taskId") }}{{ $t("ctp.colon") }}</span>
            <el-input
              class="qc-top-input"
              v-model="searchValue"
              :placeholder="$t('ctp.enterTaskID')"
            />
          </div>
          <div class="search-style">
            <span class="search-width">{{ $t("ctp.creatTime") }}{{ $t("ctp.colon") }}</span>
            <el-date-picker
              v-model="beginDate"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="timestamp"
              :placeholder="$t('ctp.startDate')"
            />
            <span>-</span>
            <el-date-picker
              v-model="endDate"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="timestamp"
              :placeholder="$t('ctp.endDate')"
            />
          </div>
          <div class="search-style">
            <span class="search-width">{{ $t("ctp.status") }}{{ $t("ctp.colon") }}</span>
            <el-select v-model="chStatus">
              <el-option
                v-for="item in searchStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="search-style">
            <span class="search-width">{{ $t("ctp.serviceProvider") }}{{ $t("ctp.colon") }}</span>
            <el-select v-model="chSupplier">
              <el-option
                id="selectSuppliers"
                v-for="item in searchSuppliers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :title="item.label"
              />
            </el-select>
          </div>
        </div>
        <div class="seatchBtn">
          <el-button @click="reset">
            {{ $t("ctp.reset") }}
          </el-button>
          <el-button type="primary" @click="handleClickSearch">
            {{ $t("ctp.search") }}
          </el-button>
        </div>
      </div>
      <div id="customer-table">
        <el-table
          v-loading="tableLoading"
          class="customer-table"
          :data="data"
          stripe
          border
          :default-sort="{prop: 'createTime', order: 'descending'}"
          style="width: 100%"
          @sort-change="sortInitData"
        >
          <div slot="empty">
            <agc-empty height="300px" />
          </div>
          <el-table-column prop="taskId" :label="$t('ctp.taskId')" min-width="190" />
          <el-table-column
            sortable="custom"
            :label="$t('ctp.creatTime')"
            min-width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.createTimeUTC">{{ Number(scope.row.createTimeLocal)|formatDate }}</span>
              <span v-else>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('ctp.translationContent')" min-width="200">
            <template slot-scope="scope">
              <el-tooltip placement="bottom-start" effect="light" popper-class="tooltip-title">
                <div slot="content">
                  <div
                    class="tip-content"
                    v-show="
                      scope.row.application && scope.row.application === 'true'
                    "
                  >
                    {{ scope.row.showTitle }}
                  </div>
                </div>
                <div slot="content">
                  <div
                    class="tip-content"
                    v-show="
                      (scope.row.application === 'false' ||
                        !scope.row.application) &&
                        !scope.row.sourceFileName
                    "
                  >
                    {{ "--" }}
                  </div>
                </div>
                <span
                  class="content-hidden"
                  v-show="
                    scope.row.application && scope.row.application === 'true'
                  "
                >{{ scope.row.showTitle ? scope.row.showTitle : "--" }}</span>
                <span
                  v-if="
                    (scope.row.application === 'false' ||
                      !scope.row.application) &&
                      !scope.row.sourceFileName
                  "
                >{{ "--" }}</span>
              </el-tooltip>
              <span
                class="content-hidden"
                v-show="
                  scope.row.sourceFileName && scope.row.sourceFileName !== ''
                "
                :title="scope.row.sourceFileName"
              >{{ scope.row.sourceFileName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sourceLanguageTwo"
            :label="$t('ctp.originLanguage')"
            min-width="180"
          />
          <el-table-column :label="$t('ctp.targetLanguage')" min-width="180">
            <template slot-scope="scope">
              <el-tooltip placement="bottom-start" effect="light" popper-class="tooltip-title">
                <div slot="content">
                  <div class="tip-content">
                    {{ scope.row.targetLanguageTwo }}
                  </div>
                </div>
                <span class="content-hidden">{{ scope.row.targetLanguageTwo }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="spName" :label="$t('ctp.serviceProvider')" min-width="180" />
          <el-table-column :label="$t('ctp.cost', {currency: payCurrency})" min-width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'init'">--</span>
              <span v-else>
                {{
                  scope.row.cost ? Number(scope.row.cost).toFixed(2) : ""
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('ctp.status')" min-width="145">
            <template slot-scope="scope">
              <span>
                {{ status[scope.row.status] }}
                <agc-icon
                  v-show="scope.row.status === 'refresh'"
                  name="warning"
                  size="14"
                  color="rgb(255, 189, 0)"
                  style="margin-left:8px"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('ctp.operation')"
            align="left"
            :min-width="language === 'cn' ? 180 : language === 'en' ? 260 : 340"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-link type="primary" @click="showDetails(scope.row)" class="orderlist-process">
                <span class="btn-hover">{{ $t("ctp.see") }}</span>
              </el-link>
              <el-link
                type="primary"
                v-if="scope.row.status === 'nopay'"
                @click="payoff(scope.row)"
                class="orderlist-process"
                :disabled="scope.row.subBtnDisabled"
              >
                <span class="btn-hover">{{ $t("ctp.payFor") }}</span>
              </el-link>
              <el-link
                type="primary"
                v-if="scope.row.status === 'init'"
                @click="edit(scope.row.taskId, scope.row.userId)"
                class="orderlist-process"
              >
                <span class="btn-hover">{{ $t("ctp.edit") }}</span>
              </el-link>
              <el-link
                type="primary"
                v-if="scope.row.status === 'nopay' || scope.row.status === 'init' || scope.row.status === 'cancel'"
                @click="delorder(scope.row.taskId)"
                class="orderlist-process"
              >
                <span class="btn-hover">{{ $t("ctp.delete") }}</span>
              </el-link>
              <el-link
                type="primary"
                v-if="scope.row.status === 'nopay'"
                @click="cancelTask(scope.row)"
                class="orderlist-process"
                :disabled="scope.row.subBtnDisabled"
              >
                <span class="btn-hover">{{ $t("ctp.cancel") }}</span>
              </el-link>
              <el-link
                type="primary"
                v-if="
                  ['completed', 'refresh', 'appraise', 'end'].indexOf(
                    scope.row.status
                  ) > -1
                "
                @click="downloadTranslated(scope.row)"
                class="orderlist-process"
              >
                <span class="btn-hover">
                  {{
                    $t("ctp.download")
                  }}
                </span>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div v-if="data.length > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
          :current-page="pager.index"
          :page-sizes="[10, 20, 50]"
          :page-size="pager.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pager.total"
        />
      </div>
    </div>
    <!-- 确认删除弹出窗 -->
    <agc-dialog :title="$t('ctp.deleteOrder')" :visible.sync="dialogVisible" width="480px">
      <div class="dialog-div-main">
        {{ $t("ctp.tips.makeSureDeleteOrder") }}
      </div>
      <div class="delete-dialog">
        <el-button type="primary" @click="deleteOrders">
          {{
            $t("ctp.ok")
          }}
        </el-button>
        <span class="dialog-btn-middle" />
        <el-button @click="cancelDelete">
          {{ $t("ctp.cancel") }}
        </el-button>
      </div>
    </agc-dialog>
    <!-- 确认取消弹出窗 -->
    <agc-dialog :title="$t('ctp.cancelOrder')" :visible.sync="cancelDialogVisible" width="480px">
      <div class="dialog-div-main">
        {{ $t("ctp.cancelTip") }}
      </div>
      <div class="delete-dialog">
        <el-button type="primary" @click="confirmCancel">
          {{
            $t("ctp.ok")
          }}
        </el-button>
        <span class="dialog-btn-middle" />
        <el-button @click="cancelDialogVisible = false">
          {{ $t("ctp.cancel") }}
        </el-button>
      </div>
    </agc-dialog>
    <!-- 下载译稿弹窗 -->
    <agc-dialog
      :title="$t('ctp.downloadFile')"
      :visible.sync="downloadDialogVisible"
      width="40%"
      @close="closeDialog"
    >
      <div>
        <div class="detail-dialog-div">
          <span class="sourceFileName">{{ $t("ctp.manuscript") }}{{ $t("ctp.colon") }}</span>
          <el-link type="primary" @click="downloadfile(1, '')">
            {{
              detailData.sourceFilePath
                ? detailData.sourceFilePath.split("_").pop().length > 20 ?
                  detailData.sourceFilePath.split("_").pop().substring(0,20) + "..."
                  : detailData.sourceFilePath.split("_").pop() : ""
            }}
          </el-link>
        </div>
        <div class="detail-dialog-div">
          <span class="sourceFileName">{{ $t("ctp.translation") }}{{ $t("ctp.colon") }}</span>
          <div class="taskReplyList">
            <el-link
              type="primary"
              :title="taskReplyList.fileName"
              @click="downloadfile(2, taskReplyList.filePath)">
              {{ fileName }}
            </el-link>
          </div>
          <span v-if="detailData.taskReplyList && detailData.taskReplyList.length < 1">
            {{ $t("ctp.Unfinished") }}</span>
        </div>
        <div class="detail-dialog-div">
          <span class="sourceFileName">{{ $t('ctp.lastUpdateTime') }}</span>
          <span v-if="taskReplyList.createTimeUTC">{{ Number(taskReplyList.createTimeLocal)|formatDate }}</span>
          <span v-else>{{ taskReplyList.createTime }}</span>
        </div>
        <el-collapse>
          <el-collapse-item>
            <template slot="title">
              {{ $t('ctp.updateHistory') }}&nbsp;
              <agc-icon name="warning" size="14" color="rgb(255, 189, 0)" style="margin-left:8px" />
            </template>
            <div>
              <template>
                <el-table
                  tooltip-effect="light"
                  id="viewTable"
                  :data="detailData.taskReplyList"
                  height="140"
                  border
                  style="width: 100%"
                >
                  <el-table-column :label="$t('ctp.updateTime')" width="210">
                    <template slot-scope="scope">
                      <span v-if="scope.row.createTimeUTC">{{ Number(scope.row.createTimeLocal)|formatDate }}</span>
                      <span v-else>{{ scope.row.createTime }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('ctp.translation')">
                    <template slot-scope="scope">
                      <el-link
                        class="content-hidden"
                        :title="scope.row.fileName"
                        type="primary"
                        @click="downloadfile(2, scope.row.filePath)"
                      >
                        {{ scope.row.fileName }}
                      </el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="cancel">
          <el-button @click="cancelDownload">
            {{ $t("ctp.cancel") }}
          </el-button>
        </div>
      </div>
    </agc-dialog>
    <!--支付弹窗-->
    <agc-dialog
      :title="$t('ctp.payFor')"
      :visible.sync="payDialogVisible"
      width="660px"
      @close="cancelPay"
    >
      <div v-if="payDialogVisible">
        <ul class="pay-ul">
          <li>
            <div class="order-time">
              <div>
                <agc-icon name="question" size="16" color="#007DFF" />
              </div>
              <span class="expire-time-tips">
                {{ $t('ctp.orderExpirationTime', {ordertime: ordertime}) }}
              </span>
            </div>
          </li>
          <li>
            <div class="label-box">
              <span class="label-box-text">{{ $t('ctp.payMethod') }}{{ $t("ctp.colon") }}</span>
            </div>
            <span class="pay-method">
              <el-radio-group v-model="payMethod" @change="changePayMethod">
                <el-radio :label="2" v-show="teamSiteId === '1'">{{ $t("ctp.thirdPayment") }}</el-radio>
                <el-radio :label="1" v-show="userType === '2'">{{ $t("ctp.balancePayment") }}</el-radio>
              </el-radio-group>
            </span>
          </li>
          <el-form label-width="auto" class="elform">
            <li>
              <div class="form-div">
                <el-form-item
                  :label="$t('ctp.taskId') + $t('ctp.colon')"
                >
                  <span>
                    <span>{{ payOrderTaskId }}</span>
                  </span>
                </el-form-item>
              </div>
            </li>
          </el-form>
          <li v-if="payMethod === 1">
            <agc-svg name="loading" size="64" v-if="loading" />
            <price-result
              v-else
              :price-info="priceInfo"
              :available-coupon-list="availableCouponList"
              :vouchers-list="vouchersList"
              @openVouchersDialog="openVouchersDialog"
              @openCouponsDialog="openCouponsDialog"
              @obtainTotal="obtainTotal"
            />
          </li>
          <li v-else>
            <span class="pay-money-li">
              <span>{{ $t("ctp.payMoney", {currency: payCurrency}) }}</span>
              <el-tooltip class="item" effect="light" placement="bottom-end">
                <div slot="content">{{ $t("ctp.tips.payMoney") }}</div>
                <agc-icon name="question" color="#c5c5c5" size="18" />
              </el-tooltip>
            </span>
            <span class="total-color">{{ Number(totalCost).toFixed(2) }}</span>
          </li>
          <li>
            <div class="qrcode-vue" v-show="payMethod === 2">
              <agc-svg name="loading" size="64" v-if="loading" />
              <div id="downloadQr" v-else>
                <QrcodeVue :value="qrValue" :size="qrSize" level="H" class="qr-bottom" />
                <div v-if="qrCodeExpirationTime">
                  <agc-translation
                    :value="$t('ctp.qrWillExpire',{minute: minutesCount, second: secondsCount})"
                    :tags="qcTimeTags"
                  />
                </div>
                <div v-else>
                  <agc-translation :value="$t('ctp.refreshQr')" :tags="refreshTags" />
                </div>
              </div>
              <div class="pay-tips">
                {{ $t('ctp.payTips') }}
              </div>
            </div>
          </li>
          <li class="currencyTipsShow" v-show="currencyTipsShow">
            <agc-icon name="warning" size="14" style="margin-right:8px" />
            <agc-translation
              style="display:inline"
              :value="$t('ctp.queryCurrency.msgContent')"
              :tags="{link: {href: linkUrl, target: '_blank'}}"
            />
          </li>
        </ul>
      </div>
      <div class="pay-dialog">
        <el-button type="primary" @click="payfor" :disabled="payBtnDisabled" class="pay-for" v-show="payMethod === 1">
          {{
            $t("ctp.payFor")
          }}
        </el-button>
        <el-button @click="cancelPay" class="pay-later">
          {{ $t("ctp.payLater") }}
        </el-button>
      </div>
    </agc-dialog>
    <!--支付等待弹窗-->
    <agc-dialog
      :title="$t('ctp.paying')"
      :visible.sync="payingDialogVisible"
      width="480px"
      @close="closeInterval"
    >
      <div class="paying-dialog">
        <span>{{ $t("ctp.payingWait") }}</span>
      </div>
      <div class="paying-dialog-timer">
        {{ $t("ctp.wait", {num: payingIncrease}) }}
      </div>
    </agc-dialog>
    <!--详情-->
    <cp-detail-dialog
      v-if="detailDialogVisiable"
      :detail-data="detailData"
      :detail-dialog-visiable="detailDialogVisiable"
      :new-task-length="detailData.NewTaskTargetLanguage"
      @changeVisiable="changeVisiable"
      @openDownloadDialog="openDownloadDialog"
    />
    <recharge-dialog ref="rechargeDialogView" />
    <agc-dialog :title="$t('ctp.tips')" :visible.sync="payTipsVisible" width="420px">
      <agc-translation :value="payTip" :tags="payTipTags" />
      <span slot="footer">
        <el-button type="primary" @click="payTipsVisible = false">{{ $t('ctp.close') }}</el-button>
      </span>
    </agc-dialog>
    <!-- 满减券弹窗 -->
    <CouponsDialog
      v-if="couponsVisible"
      :coupons-list="availableCouponList"
      :un-available-list="unAvailableCouponList"
      @selectCoupon="selectCoupon"
      @hideDialog="hideCouponsDialog"
    />
    <!-- 代金券弹窗 -->
    <VouchersDialog
      v-if="couchersVisible"
      :vouchers-list="vouchersList"
      @selectVoucher="selectVoucher"
      @hideDialog="hideDialog"
    />
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import moment from 'moment';
import cpDetailDialog from './cp-detail-dialog.vue';
import rechargeDialog from './recharge-dialog.vue';
import priceResult from '../common/PriceResult.vue';
import QrcodeVue from 'qrcode.vue';
import { getConfigs } from '../../api/sp.js';
import { getUserId, getTeamId } from '@appstore/agc-utils';
import { PAYGUIDE_TAGS, RECHARGELINK, HELPLINK, LANGOBJ, VOUCHER_TYPE,
         COUPON_TYPE } from '../../constant/index.js';
import { floatToFixed } from '../../util/priceProcessing.js';
import CouponsDialog from './coupons-dialog.vue';
import VouchersDialog from './vouchers-dialog.vue';
import { getLanguage, timestampToDate } from '../../util/common.js';
import {
  interactiveHaLog,
  interactiveHaLogLanguage
} from '../../util/interactiveHaLog.js';
export default {
  name: 'Uorderlist',
  components: {
    'cp-detail-dialog': cpDetailDialog,
    rechargeDialog,
    QrcodeVue,
    priceResult,
    CouponsDialog,
    VouchersDialog
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      return timestampToDate(value);
    }
  },
  data() {
    return {
      searchStatus: [
        { value: 'all', label: this.$t('ctp.all') },
        { value: 'init', label: this.$t('ctp.init') },
        { value: 'nopay', label: this.$t('ctp.nopay') },
        { value: 'pay', label: this.$t('ctp.pay') },
        { value: 'overtime', label: this.$t('ctp.overtime') },
        { value: 'completed', label: this.$t('ctp.completed') },
        { value: 'refresh', label: this.$t('ctp.upDated') },
        { value: 'end', label: this.$t('ctp.end') },
        { value: 'cancel', label: this.$t('ctp.expired') }
      ],
      searchSuppliers: [{ value: 'all', label: this.$t('ctp.all') }],
      agcUid: '',
      chStatus: '',
      chSupplier: '',
      beginDate: '',
      endDate: moment().valueOf(),
      searchValue: '',
      data: [],
      taskId: '',
      totalCost: 0.0,
      detailDialogVisiable: false,
      detailData: {},
      taskReplyList: [],
      payDialogVisible: false,
      payingDialogVisible: false,
      payingIncrease: 0,
      payingTimer: '',
      dialogVisible: false,
      downloadDialogVisible: false,
      appraiseDialogVisible: false,
      pager: {
        total: 0,
        index: 1,
        pageSize: 10
      },
      createTimeAscend: false,
      supplier: [],
      appraise: {
        spId: '',
        spName: '',
        taskId: '',
        delivery: 0,
        quality: 0,
        service: 0,
        createBy: ''
      },
      remarks: '',
      status: {
        init: this.$t('ctp.init'),
        nopay: this.$t('ctp.nopay'),
        pay: this.$t('ctp.pay'),
        translate: this.$t('ctp.pay'),
        overtime: this.$t('ctp.overtime'),
        completed: this.$t('ctp.completed'),
        refresh: this.$t('ctp.upDated'),
        end: this.$t('ctp.end'),
        cancel: this.$t('ctp.expired')
      },
      productInfo: '',
      payOrderTaskId: '',
      paySpId: '',
      languages: [],
      language:
        localStorage.getItem('agc_lang') ||
        localStorage.getItem('agc_lang_site'),
      payCurrency: '--',
      isRefresh: false,
      fileName: '',
      zoneIdData: '',
      HAoriginLanguageData: '',
      HAtargetLanguageData: [],
      payMethod: null,
      qrValue: '',
      qrSize: 150,
      qrCodeExpirationTime: 0,
      ordertime: '',
      qcTimeTags: {
        highlight: {
          style: {
            color: '#B92E29'
          }
        }
      },
      refreshTags: {
        refresh: {
          click: () => {
            this.clearTimer();
            this.queryQrcode();
          }
        }
      },
      timerCount: null,
      timerQuery: null,
      teamSiteId: sessionStorage.getItem('agc_team_siteId'),
      intervalTime: 2000,
      loading: false,
      userId: getUserId(),
      teamId: getTeamId(),
      payTipsVisible: false,
      payTip: this.$t('ctp.serviceMarkets.payTips'),
      payTipTags: PAYGUIDE_TAGS,
      userType: sessionStorage.getItem('agc_userType'),
      payBtnDisabled: false,
      currency: '',
      linkUrl: RECHARGELINK,
      currencyTipsShow: false,
      helpLink: HELPLINK,
      tableLoading: false,
      priceInfo: {},
      cancelDialogVisible: false,
      cancelTaskId: '',
      // 代金券集合
      vouchersList: [],
      // 满减券集合
      couponsList: [],
      // 不可用满减券
      unAvailableCouponList: [],
      // 可用满减券
      availableCouponList: [],
      // 满减券弹窗
      couponsVisible: false,
      // 代金券弹窗
      couchersVisible: false,
      serviceName: '',
      // 正在支付订单信息
      payingInformation: ''
    };
  },
  computed: {
    minutesCount() {
      const minutes = String(Math.floor(this.qrCodeExpirationTime / 60));
      if (minutes.length === 1) {
        return `0${minutes}`;
      } else {
        return minutes;
      }
    },
    secondsCount() {
      const second = String(this.qrCodeExpirationTime % 60);
      if (second.length === 1) {
        return `0${second}`;
      } else {
        return second;
      }
    } },
  watch: {
    payMethod() {
      this.balancePay();
    }
  },
  mounted() {
    this.zoneIdData = sessionStorage.getItem('tszoneId');
    /** 调用接口关联项目 */
    this.chStatus = this.searchStatus[0].value;
    this.chSupplier = this.searchSuppliers[0].value;
    this.agcUid =
      Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
    sessionStorage.removeItem('agc_translate_skip_taskId');
    const productId = Cookie.get('productId') || '';
    this.productInfo = sessionStorage.getItem('productInfo') !== '{}' ? JSON.parse(sessionStorage.getItem('productInfo')) : '';
    if (this.productInfo && this.productInfo.productID) {
      this.getSupplier();
      this.initData(1);
    } else if (productId && !this.productInfo) {
      const { appName } = sessionStorage.getItem('appInfo') ? JSON.parse(sessionStorage.getItem('appInfo')) : '';
      this.$alertMsg(
        this.$t('ctp.noPermission', { appName }),
        this.$t('ctp.warning')
      );
    } else {
      AGC.attachAppToProject();
      window.addEventListener('message', e => {
        if (
          e.data &&
          e.data.operation === 'attachAppToProject' &&
          e.data.result === 'SUCCESS'
        ) {
          parent.window.location.reload();
        }
      });
    }
    if (this.userType !== '1') {
      this.queryCurrency();
    } else {
      this.payCurrency = 'CNY';
    }
  },
  methods: {
    hideCouponsDialog() {
      this.couponsVisible = false;
    },
    hideDialog() {
      this.couchersVisible = false;
    },
    selectVoucher(selectedIndex) {
      this.vouchersList.forEach((item, index) => {
        if (selectedIndex === index) {
          item.checked = !item.checked;
        }
      });
      this.vouchersList = [...this.vouchersList];
    },
    selectCoupon(selectedIndex) {
      this.availableCouponList.forEach((item, index) => {
        if (selectedIndex === index) {
          item.checked = !item.checked;
        } else {
          item.checked = false;
        }
      });
      this.availableCouponList = [...this.availableCouponList];
    },
    openVouchersDialog() {
      if (this.vouchersList.length > 0) {
        this.couchersVisible = true;
      }
    },
    obtainTotal(val) {
      this.totalCost = val;
    },
    openCouponsDialog() {
      if (this.availableCouponList.length > 0) {
        this.couponsVisible = true;
      }
    },
    handleVouchersList(data) {
      const timeNow = moment().format('YYYYMMDDHHmmss');
      this.vouchersList = this.vouchersList.filter(
        item => item.remainingAmount > 0 && parseFloat(item.expireTime) > timeNow && item.status !== 0
      );
      // 正在支付的订单里的代金券，已使用代金券
      const { deductedRewardInfos } = this.payingInformation;
      const usVouchersList = JSON.parse(deductedRewardInfos);
      this.vouchersList.forEach(item => {
        item.expireDate = moment(item.expireTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
        item.checked = usVouchersList.some(val => val.planId === item.planId);
      });
    },
    handleCouponList(data) {
      const timeNow = moment().format('YYYYMMDDHHmmss');
      this.couponsList = this.couponsList.filter(item => parseFloat(item.expireTime) > timeNow && item.status === 1);
      this.couponsList.forEach(item => {
        item.expireDate = moment(item.expireTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
        item.remainingAmount = floatToFixed(item.remainingAmount);
      });
      // 不可用满减券
      this.unAvailableCouponList = this.couponsList.filter(item => item.minimumAmount > data.order);
      // 可用满减券
      this.availableCouponList = this.couponsList.filter(item => item.minimumAmount <= data.order);
      // 正在支付的订单里面的满减券，已使用满减券
      const { deductedRewardInfos } = this.payingInformation;
      const usCouponList = JSON.parse(deductedRewardInfos);
      // 已用满减券和未用区分
      this.availableCouponList.forEach(item => {
        item.checked = usCouponList.some(val => val.planId === item.planId);
      });
    },
    // 支付或保存接口
    servicePayOrSave(body) {
      const url = 'translation/v1/cp/pay';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { body },
          'POST',
          this.zoneIdData,
          20000,
          resolve,
          reject
        );
      });
    },
    // 获取优惠券信息接口
    serviceCoupons() {
      const body = `?language=${LANGOBJ[this.language]}&type=0&pageSize=100&pageIndex=1&serviceNames=${this.serviceName}`;
      const url = `ass/subscribe-service/v1/activities/user${body}`;
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          {},
          'get',
          this.zoneIdData,
          3000,
          resolve,
          reject
        );
      });
    },
    // 试算接口
    servicePriceCalculation(body) {
      const url = 'translation/v1/cp/getServiceProvider';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { body },
          'POST',
          this.zoneIdData,
          7000,
          resolve,
          reject
        );
      });
    },
    // 取消订单弹窗
    cancelTask(data) {
      this.cancelDialogVisible = true;
      this.cancelTaskId = data.taskId;
    },
    // 取消订单接口
    serviceTaskStatus(body) {
      const url = 'translation/v1/cp/task/status';
      return new Promise((resolve, reject) => {
        AGC.callServiceAPI(
          url,
          { body },
          'POST',
          this.zoneIdData,
          15000,
          resolve,
          reject
        );
      });
    },
    // 取消订单
    async confirmCancel() {
      const body = {};
      body.taskId = this.cancelTaskId;
      body.status	= 'init';
      let res;
      try {
        res = await this.serviceTaskStatus(body);
      } catch (error) {
        this.cancelDialogVisible = false;
        this.$promptMsg(
          `${this.$t('ctp.tips.payError.abnormal')}`,
          this.$t('ctp.tips')
        );
        return error;
      }
      if (res && res.ret && res.ret.code === 0) {
        // 刷新列表
        this.initData(1);
        this.cancelDialogVisible = false;
        return;
      }
      this.cancelDialogVisible = false;
      this.$promptMsg(
        `${this.$t('ctp.tips.payError.abnormal')}`,
        this.$t('ctp.tips')
      );
    },
    async changePayMethod(val) {
      if (val === 2) {
        this.queryQrcode();
        return;
      }
      this.clearTimer();
      this.loading = true;
      this.restList();
      const priceBody = {
        spId: this.paySpId,
        taskId: this.payOrderTaskId,
        siteId: this.teamSiteId
      };
      const { taskId, notSupportLangId, status, spName,
              remarksForSp, isAgreePrivacy, spId, devEmail, expectDeliveryDay } = this.payingInformation;
      const { agreementUrl } = this.searchSuppliers.find(item => item.value === spId);
      const saveBody = {
        payChannel: 1,
        siteId: this.teamSiteId,
        save00: true,
        taskId, notSupportLangId,
        status, agreementUrl,
        supplierName: spName, remarksForSp,
        isAgreePrivacy, supplierId: spId,
        devEmail, expectDeliveryDay
      };
      const cacelBody = {
        taskId: this.payOrderTaskId
      };
      function codeCkech(res) {
        if (!res || !res.ret || res.ret.code !== 0) {
          return false;
        }
        return true;
      }
      // 取消
      this.serviceTaskStatus(cacelBody).then(res => codeCkech(res) ? this.servicePriceCalculation(priceBody) : Promise.reject()).then(res => {
        if (codeCkech(res)) {
          this.priceInfo = res.serviceProviders[0];
          return Promise.all([this.servicePayOrSave(saveBody), this.serviceCoupons()]);
        }
        return Promise.reject();
      }).then(res => {
        this.loading = false;
        const userActivities = res[1].userMarketingActivities;
        this.vouchersList = userActivities.filter(item => item.goodsType === VOUCHER_TYPE);
        this.couponsList = userActivities.filter(item => item.goodsType === COUPON_TYPE);
        this.handleVouchersList(this.priceInfo);
        this.handleCouponList(this.priceInfo);
      }).catch(err => {
        this.payMethod = 2;
        this.loading = false;
        this.$promptMsg(
          `${this.$t('ctp.tips.payError.abnormal')}`,
          this.$t('ctp.tips')
        );
        return err;
      });
    },
    queryCurrency() {
      const header = {};
      const url = 'ass/subscribe-service/v1/accountInfo/currency';
      AGC.callServiceAPI(
        url,
        { header },
        'GET',
        this.zoneIdData,
        3000,
        res => {
          this.currency = res.currency;
          if (res.currency) {
            this.payCurrency = res.currency;
          } else if (this.teamSiteId === '1') {
            this.payCurrency = 'CNY';
          } else {
            this.payCurrency = 'USD';
          }
        });
    },
    balancePay() {
      if (this.payMethod === 1 && this.currency.length <= 0) {
        this.payBtnDisabled = true;
        this.currencyTipsShow = true;
      } else {
        this.payBtnDisabled = false;
        this.currencyTipsShow = false;
      }
    },
    handleClickSearch() {
      interactiveHaLog({
        title: '翻译服务CP--查询',
        tagType: 'TranslationService',
        translationServiceId: ''
      });
      this.initData(1);
    },
    // 加载订单列表
    initData(num) {
      const body = {
        length: this.pager.pageSize,
        userId: this.agcUid,
        createTimeAscend: this.createTimeAscend
      };
      if (num === 1) {
        body.start = 0;
        this.pager.index = 1;
      }
      if (num === 2) {
        body.start =
          ((this.pager.index < 1 ? 1 : this.pager.index) - 1) *
          this.pager.pageSize;
      }
      if (this.chStatus !== 'all') {
        body.status = this.chStatus;
      }
      if (this.searchValue && this.searchValue.length > 0) {
        body.taskId = this.searchValue;
      }
      if (this.beginDate) {
        body.beginDate = this.beginDate;
      }

      if (this.endDate) {
        body.endDate = this.endDate;
      }
      if (this.chSupplier !== 'all') {
        body.spId = this.chSupplier;
      }
      const header = {};
      const url = 'translation/v1/cp/taskList';
      AGC.callServiceAPI(
        url,
        { body, header },
        'POST',
        this.zoneIdData,
        3000,
        res => {
          if (res.ret.code === 0) {
            res.data.forEach((el) => {
              el.subBtnDisabled = false;
              el.createTimeLocal = el.createTimeUTC;
              el.expectDeliveryTimeLocal = el.expectDeliveryTimeUTC;
              if (el.application) {
                el.applicationName2 = el.applicationName
                  ? el.applicationName
                  : '';
                el.applicationName = el.applicationName
                  ? `${el.applicationName}-`
                  : '';
                el.appIntroduce2 = el.appIntroduce ? el.appIntroduce : '';
                el.appIntroduce = el.appIntroduce ? `${el.appIntroduce}-` : '';
                el.appIntroduceInshort2 = el.appIntroduceInshort
                  ? el.appIntroduceInshort
                  : '';
                el.appFeatures2 = el.appFeatures ? el.appFeatures : '';
                el.appFeatures = `-${el.appFeatures}` ? el.appFeatures : '';
                el.showTitle =
                  el.applicationName +
                  el.appIntroduce +
                  el.appIntroduceInshort +
                  el.appFeatures;
              } else {
                el.showTitle = '';
              }
              el.spId ? el.spName : (el.spName = '--');
            });
            // 切换不同的语言
            getLanguage().then(resp => {
              this.languages = resp;
              res.data.forEach(row => {
                const tarLanguageArr = [];
                resp.forEach(item => {
                  if (item.languageId === row.sourceLanguageId) {
                    row.sourceLanguage =
                      this.language === 'cn'
                        ? item.languageNameCh
                        : this.language === 'en'
                          ? item.languageNameEn
                          : item.languageNameRu;
                    row.sourceLanguageTwo = row.sourceLanguage;
                  } else if (!row.sourceLanguageId) {
                    row.sourceLanguageTwo = '--';
                  }

                  row.targetLanguageId.split(',').forEach(lang => {
                    if (lang === item.languageId) {
                      tarLanguageArr.push(
                        this.language === 'cn'
                          ? item.languageNameCh
                          : this.language === 'en'
                            ? item.languageNameEn
                            : item.languageNameRu
                      );
                    }
                  });
                  if (tarLanguageArr.length) {
                    const symbolText = this.language === 'cn' ? '，' : ',';
                    row.targetLanguage = tarLanguageArr.join(symbolText);
                    row.targetLanguageTwo = row.targetLanguage;
                  } else if (!tarLanguageArr.length) {
                    row.targetLanguageTwo = '--';
                  }
                });
              });
              this.data = res.data;
              this.updateFileMetaData();
            });
            this.pager.total = res.total ? Number.parseInt(res.total) : 0;
          } else {
            this.$alertMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.warning')
            );
            this.data = [];
            this.pager.total = 0;
          }
        },
      );
    },
    sortInitData(param) {
      if (param.order === 'ascending') {
        this.createTimeAscend = true;
      } else {
        this.createTimeAscend = false;
      }
      this.initData(2);
    },
    updateFileMetaData() {
      this.data.forEach(item => {
        if (item.sourceFilePath && item.spUid && item.status !== 'init') {
          const storage = this.$agc.storage();
          const path = item.sourceFilePath;
          const childPath =
            path.indexOf(':') > -1
              ? path.substring(path.indexOf(':') + 1)
              : path;
          const child = storage.storageReference().child(childPath);
          child
            .getFileMetadata()
            .then(data => {
              if (!data.customMetadata.teamid) {
                child
                  .updateFileMetadata({ teamid: this.$agc.md5(item.spUid) })
                  .then()
                  .catch();
              }
            })
            .catch();
        }
      });
    },
    createTranslateTask() {
      interactiveHaLog({
        title: '翻译服务CP--新建',
        tagType: 'TranslationService',
        translationServiceId: ''
      });
      sessionStorage.removeItem('agc_translate_skip_taskId');
      this.$router.push({ path: '/cp/create' });
    },
    // 重置
    reset() {
      this.searchValue = '';
      this.beginDate = '';
      this.endDate = moment().valueOf();
      this.chStatus = this.searchStatus[0].value;
      this.chSupplier = this.searchSuppliers[0].value;
    },
    openDownloadDialog() {
      this.detailDialogVisiable = false;
    },
    // 编辑跳转
    edit(taskid) {
      interactiveHaLog({
        title: '翻译服务CP--编辑',
        tagType: 'TranslationService',
        translationServiceId: taskid
      });
      sessionStorage.setItem('agc_translate_skip_taskId', taskid);
      this.$router.push({ path: '/cp/create', query: { taskId: taskid } });
    },
    increase() {
      this.payingIncrease++;
    },
    closeInterval() {
      clearInterval(this.payingTimer);
      this.payingIncrease = 0;
    },
    // 支付
    payfor() {
      this.payDialogVisible = false;
      this.payingDialogVisible = true;
      this.payingTimer = setInterval(this.increase, 1000);
      const body = {};
      body.taskId = this.payOrderTaskId;
      body.siteId = this.teamSiteId;
      const allCouponList = [...this.availableCouponList, ...this.vouchersList];
      if (allCouponList.length > 0) {
        body.deductedRewardInfos = allCouponList.filter(item => item.checked).map(el => {
          const { planId, goodsId, goodsType } = el;
          return { planId, goodsId, type: goodsType };
        });
        body.serviceName = this.serviceName;
        body.language = LANGOBJ[this.language];
      }
      interactiveHaLogLanguage({
        title: '翻译服务CP--支付',
        tagType: 'TranslationService',
        Data: {
          translationServiceId: this.payOrderTaskId,
          originLanguage: this.HAoriginLanguageData,
          targetLanguage: this.HAtargetLanguageData
        }
      });
      // 调用支付函数。。。。
      const url = 'translation/v1/cp/pay';
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        20000,
        res => {
          this.payingDialogVisible = false;
          this.restList();
          if (res.ret.code === 0) {
            this.initData(1);
            this.$promptMsg(
              this.$t('ctp.tips.paySuccess'),
              this.$t('ctp.tips')
            );
          } else if (res.ret.code === 141940557591) {
            if (this.userId === this.teamId) {
              this.$refs.rechargeDialogView.handleOpenRecharge();
            } else {
              this.payTipsVisible = true;
            }
          } else {
            this.$alertMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.restList();
          this.payingDialogVisible = false;
          this.$alertMsg(
            this.$t('ctp.error.payError.overtime'),
            this.$t('ctp.warning')
          );
          this.data.forEach(item => {
            if (item.taskId === this.payOrderTaskId) {
              item.subBtnDisabled = true;
            }
            setTimeout(() => {
              item.subBtnDisabled = false;
            }, 10000);
          });
          return err;
        }
      );
    },
    changeVisiable(dialogVisible) {
      this.detailDialogVisiable = dialogVisible;
    },
    // 获取供应商
    getSupplier() {
      const url = 'translation/v1/cp/getServiceProvider';
      AGC.callServiceAPI(
        url,
        { header: {}, body: {} },
        'POST',
        this.zoneIdData,
        7000,
        res => {
          this.searchSuppliers = [{ value: 'all', label: this.$t('ctp.all') }];
          if (res.serviceProviders && res.serviceProviders.length > 0) {
            res.serviceProviders.forEach(e => {
              let spNameValue = '';
              e.languages.forEach(el => {
                if (this.language === 'cn' && el.language === 'zh_CN') {
                  spNameValue = el.spName;
                } else if (this.language === 'en' && el.language === 'en_US') {
                  spNameValue = el.spName;
                } else if (this.language === 'ru' && el.language === 'ru_RU') {
                  spNameValue = el.spName;
                } else if (el.language === 'en_US') {
                  spNameValue = el.spName;
                }
              });
              this.searchSuppliers.push({ value: e.spId, label: spNameValue, serviceName: e.serviceName, agreementUrl: e.agreementUrl });
            });
          } else {
            this.$alertMsg(
              this.$t('ctp.error.noProvider'),
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.searchProviderFail'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    // 删除弹框
    delorder(taskid) {
      interactiveHaLog({
        title: '翻译服务CP--删除',
        tagType: 'TranslationService',
        translationServiceId: taskid
      });
      if (taskid && taskid.length > 0) {
        this.taskId = taskid;
      } else {
        this.taskId = '';
      }
      this.dialogVisible = true;
    },
    // 删除订单
    deleteOrders() {
      const body = {
        taskIds: [this.taskId],
        userId: this.agcUid
      };
      const header = {};
      const url = 'translation/v1/cp/deleteTask';
      AGC.callServiceAPI(
        url,
        { header, body },
        'POST',
        this.zoneIdData,
        3000,
        res => {
          this.dialogVisible = false;
          if (res.ret.code === 0) {
            this.$promptMsg(
              this.$t('ctp.tips.deleteTaskSuccess'),
              this.$t('ctp.tips')
            );
            this.pager.index = 1;
            this.initData(1);
          } else {
            this.$alertMsg(
              this.$t('ctp.error.deleteTaskFail'),
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.deleteTaskFail'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    // 点击支付，支付按钮置灰
    payBtnDisable(val) {
      this.data.forEach(item => {
        if (item.taskId === this.payOrderTaskId) {
          item.subBtnDisabled = val;
        }
      });
    },
    // 点支付，弹窗
    payoff(val) {
      this.payingInformation = JSON.parse(JSON.stringify(val));
      this.tableLoading = true;
      const { taskId, spId, payChannel, expirationTime, createTimeUTC,
              expectDeliveryTimeUTC } = val;
      this.payOrderTaskId = taskId;
      this.paySpId = spId;
      this.payMethod = payChannel;
      this.serviceName = this.searchSuppliers.find(item => item.value === spId).serviceName;
      if (expirationTime) {
        this.ordertime = timestampToDate(Number(expirationTime));
      } else {
        const time = expectDeliveryTimeUTC ? expectDeliveryTimeUTC : createTimeUTC;
        this.ordertime = timestampToDate(Number(time) + 7 * 24 * 60 * 60 * 1000);
      }
      this.payBtnDisable(true);
      if (this.payMethod !== 1) {
        this.queryQrcode();
        this.getIntervalTime();
        return;
      }
      const siteId = this.teamSiteId;
      const priceBody = { taskId, spId, siteId };
      // 试算
      const priceRes = this.servicePriceCalculation(priceBody);
      const couponsRes = this.serviceCoupons();
      Promise.all([priceRes, couponsRes]).then(val => {
        const langList = [];
        val[0].serviceProviders[0].lang.forEach(item => {
          langList.push(item.targetLanguageId);
        });
        this.HAoriginLanguageData = this.enLangTocnLang(val[0].serviceProviders[0].sourceLanguageId, 1);
        this.HAtargetLanguageData = this.enLangTocnLang(langList, 2);
        val[0].serviceProviders = val[0].serviceProviders.filter(item => item.spId === spId);
        this.totalCost = val[0].serviceProviders[0].total;
        this.priceInfo = val[0].serviceProviders[0];
        const userActivities = val[1].userMarketingActivities;
        this.vouchersList = userActivities.filter(item => item.goodsType === VOUCHER_TYPE);
        this.couponsList = userActivities.filter(item => item.goodsType === COUPON_TYPE);
        this.handleVouchersList(val[0].serviceProviders[0]);
        this.handleCouponList(val[0].serviceProviders[0]);
        this.payDialogVisible = true;
        this.tableLoading = false;
        this.payBtnDisable(false);
      }).catch(err => {
        this.loading = false;
        this.payBtnDisable(false);
        this.tableLoading = false;
        this.$alertMsg(
          this.$t('ctp.error.systemBusy'),
          this.$t('ctp.warning')
        );
        return err;
      });
    },
    // 获取支付二维码
    queryQrcode() {
      this.loading = true;
      this.clearTimer();
      const url = 'translation/v1/cp/pay';
      const body = {};
      const allCouponList = [...this.availableCouponList, ...this.vouchersList];
      if (allCouponList.length > 0) {
        body.deductedRewardInfos = allCouponList.filter(item => item.checked).map(el => {
          const { planId, goodsId, goodsType } = el;
          return { planId, goodsId, type: goodsType };
        });
        body.serviceName = this.serviceName;
        body.language = LANGOBJ[this.language];
      }
      body.taskId = this.payOrderTaskId;
      body.payChannel = this.payMethod;
      body.siteId = this.teamSiteId;
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        20000,
        res => {
          this.loading = false;
          if (res.ret.code === 0) {
            this.payDialogVisible = true;
            this.tableLoading = false;
            this.payBtnDisable(false);
            const { payCodeInfo } = res;
            if (!payCodeInfo) {
              this.$promptMsg(
                this.$t('ctp.tips.paySuccess'),
                this.$t('ctp.tips')
              );
              this.payDialogVisible = false;
              this.initData(1);
              return;
            }
            this.totalCost = res.totalActualPaymentAmount;
            this.qrValue = payCodeInfo.payCodeUrl;
            this.qrCodeExpirationTime = payCodeInfo.payCodeExpireTime ? payCodeInfo.payCodeExpireTime : 300;
            this.clearTimer();
            this.startTimerCount();
            this.startTimerQuery();
          } else {
            this.payMethod = 1;
            this.payBtnDisable(false);
            this.tableLoading = false;
            this.$alertMsg(
              this.$t('ctp.error.systemBusy'),
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.payMethod = 1;
          this.loading = false;
          this.tableLoading = false;
          this.payBtnDisable(false);
          this.$alertMsg(
            this.$t('ctp.error.systemBusy'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    queryPayResult() {
      const body = {};
      body.taskId = this.payOrderTaskId;
      const url = 'translation/v1/cp/payResult';
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        3000,
        res => {
          if (res.status === 0) {
            this.$promptMsg(
              this.$t('ctp.tips.paySuccess'),
              this.$t('ctp.tips')
            );
            this.payDialogVisible = false;
            this.initData(1);
          }
        }
      );
    },
    startTimerCount() {
      this.timerCount = setInterval(() => {
        if (this.qrCodeExpirationTime > 0) {
          this.qrCodeExpirationTime--;
        } else {
          this.clearTimer();
        }
      }, 1000);
    },
    startTimerQuery() {
      this.timerQuery = setInterval(this.queryPayResult, 2000);
    },
    clearTimer() {
      if (this.timerCount) {
        clearInterval(this.timerCount);
        this.timerCount = null;
      }
      if (this.timerQuery) {
        clearInterval(this.timerQuery);
        this.timerQuery = null;
      }
    },
    getIntervalTime() {
      getConfigs('CP', 'TranslationReq.Interval')
        .then(res => {
          if (res.ret.code === 0) {
            this.intervalTime = (res.data[0].ruleValue) ? Number(res.data[0].ruleValue) : 2000;
          }
        });
    },
    cancelDelete() {
      this.dialogVisible = false;
    },
    cancelDownload() {
      this.downloadDialogVisible = false;
      this.initData(1);
    },
    cancelPay() {
      this.HAoriginLanguageData = '';
      this.HAtargetLanguageData = [];
      this.restList();
      this.clearTimer();
      this.payDialogVisible = false;
    },
    restList() {
      this.vouchersList = [];
      this.couponsList = [];
      this.unAvailableCouponList = [];
      this.availableCouponList = [];
    },
    // 分页
    handleSizeChange(val) {
      this.pager.pageSize = val;
      this.initData(2);
    },
    handleCurrentChange(val) {
      this.pager.index = val;
      this.initData(2);
    },

    // 评价弹窗
    openAppraiseDialog(taskId, spId, spName) {
      this.appraise.spId = spId;
      this.appraise.spName = spName;
      this.appraise.taskId = taskId;
      this.appraiseDialogVisible = true;
    },

    // 选择供应商评分
    chooseStar(type, index) {
      switch (type) {
      case 1:
        this.appraise.delivery = index;
        break;
      case 2:
        this.appraise.quality = index;
        break;
      case 3:
        this.appraise.service = index;
        break;
      }
    },
    // 评价供应商
    submitAppraise() {
      if (!this.appraise.taskId) {
        this.$promptMsg(this.$t('ctp.tips.chooseOrder'), this.$t('ctp.tips'));
        return;
      }
      const body = {
        param: [
          {
            spId: this.appraise.spId,
            spName: this.appraise.spName,
            taskId: this.appraise.taskId,
            timelinessOfDelivery: this.appraise.delivery,
            qualitySatisfaction: this.appraise.quality,
            serviceSatisfaction: this.appraise.service,
            createBy: this.agcUid,
            remarks: this.remarks
          }
        ]
      };
      const header = {};
      const url = 'translation/v1/cp/addAppraise';
      AGC.callServiceAPI(
        url,
        { header, body },
        'POST',
        null,
        3000,
        res => {
          this.appraiseDialogVisible = false;
          if (res.ret.code === 0) {
            this.appraise.delivery = 0;
            this.appraise.taskId = '';
            this.appraise.spId = '';
            this.appraise.spName = '';
            this.appraise.quality = 0;
            this.appraise.service = 0;
            this.appraise.createBy = '';
            this.remarks = '';
            this.initData(1);
            this.$promptMsg(
              this.$t('ctp.tips.evaluateSuccess'),
              this.$t('ctp.tips')
            );
          } else {
            this.$alertMsg(
              this.$t('ctp.error.exitSensitiveInfo') + res.sensitiveInfo,
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.systemError'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },

    // 点下载
    downloadTranslated(data) {
      this.detailData = data;
      this.detailData.agcUid = this.agcUid;
      let url = 'translation/v1/cp/replyList';
      AGC.callServiceAPI(
        url,
        { body: { taskId: data.taskId } },
        'POST',
        this.zoneIdData,
        3000,
        res => {
          if (res.ret.code === 0) {
            res.data.forEach((el) => {
              el.createTimeLocal = el.createTimeUTC;
            });
            this.detailData.taskReplyList = res.data;
            this.taskReplyList = res.data[0];
            this.fileName = res.data[0].fileName.length > 20
              ? `${res.data[0].fileName.substring(0, 20)}...` : res.data[0].fileName;
            this.downloadDialogVisible = true;
            url = 'translation/v1/cp/queryNotice';
            AGC.callServiceAPI(
              url,
              { header: {}, body: { taskId: data.taskId } },
              'POST',
              this.zoneIdData,
              3000,
              res => {
                if (res.ret.code === 0) {
                  this.detailData.notice = res.data;
                  this.downloadDialogVisible = true;
                }
              },
              err => {
                this.$alertMsg(
                  this.$t('ctp.error.systemError'),
                  this.$t('ctp.warning')
                );
                return err;
              }
            );
          } else {
            this.$promptMsg(
              this.$t('ctp.tips.noDownloadTranslation'),
              this.$t('ctp.tips')
            );
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.systemError'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    // 点查询
    showDetails(data) {
      interactiveHaLog({
        title: '翻译服务CP--查看',
        tagType: 'TranslationService',
        translationServiceId: data.taskId
      });
      this.detailData = data;
      this.detailData.currency = this.payCurrency;
      const body = {
        taskId: data.taskId
      };
      let url = 'translation/v1/cp/taskDetails';
      AGC.callServiceAPI(url, { body }, 'POST', this.zoneIdData, '3000', res => {
        if (res.data) {
          this.detailData.TaskTargetLanguage = res.data[0].TaskTargetLanguage;
          let allPay = 0; // 总价
          let actualPay = 0; // 实际支付
          this.detailData.TaskTargetLanguage.forEach((item, index) => {
            if (item == null) {
              this.detailData.TaskTargetLanguage.splice(index, 1);
            }
          });
          this.detailData.TaskTargetLanguage.forEach((item) => {
            allPay +=
              Number(item.preCost ? item.preCost : 0) *
              Number(item.totalWords > 50 ? item.totalWords : 50);
            actualPay += Number(item.totalCost);
            if (item.originalCost === '-1.0') {
              item.originalCost =
                Number(item.costWords <= 50 ? 50 : item.costWords) *
                Number(item.preCost ? item.preCost : 0);
            }
          });
          this.detailData.allPay = allPay.toFixed(2);
          this.detailData.actualPay = actualPay.toFixed(2);
          this.detailData.agcUid = this.agcUid;
          url = 'translation/v1/cp/queryNotice';
          AGC.callServiceAPI(
            url,
            { header: {}, body: { taskId: this.detailData.taskId } },
            'POST',
            this.zoneIdData,
            3000,
            res => {
              if (res.ret.code === 0) {
                this.detailData.notice = res.data;
                const notSupportLangId = this.detailData.notSupportLangId
                  ? this.detailData.notSupportLangId.split(',')
                  : [];
                const notSupportLangName = [];
                if (notSupportLangId.length > 0) {
                  notSupportLangId.forEach(el => {
                    this.languages.forEach(lang => {
                      if (el === lang.languageId) {
                        if (this.language === 'cn') {
                          notSupportLangName.push(lang.languageNameCh);
                        } else if (this.language === 'en') {
                          notSupportLangName.push(lang.languageNameEn);
                        } else if (this.language === 'ru') {
                          notSupportLangName.push(lang.languageNameRu);
                        } else {
                          notSupportLangName.push(lang.languageNameEn);
                        }
                      }
                      const NewTaskTargetLanguage = [];
                      this.detailData.TaskTargetLanguage.forEach(item => {
                        if (
                          el !== item.targetLanguageId &&
                          item.targetLanguageId === lang.languageId
                        ) {
                          if (this.language === 'cn') {
                            item.targetLanguageName = lang.languageNameCh;
                          } else if (this.language === 'en') {
                            item.targetLanguageName = lang.languageNameEn;
                          } else if (this.language === 'ru') {
                            item.targetLanguageName = lang.languageNameRu;
                          } else {
                            item.targetLanguageName = lang.languageNameEn;
                          }
                        }

                        if (!notSupportLangId.includes(item.targetLanguageId)) {
                          NewTaskTargetLanguage.push(item);
                        }
                      });
                      this.detailData.NewTaskTargetLanguage = NewTaskTargetLanguage
                        ? NewTaskTargetLanguage
                        : [];
                    });
                  });
                } else {
                  this.detailData.TaskTargetLanguage.forEach(item => {
                    this.languages.forEach(lang => {
                      if (item.targetLanguageId === lang.languageId) {
                        if (this.language === 'cn') {
                          item.targetLanguageName = lang.languageNameCh;
                        } else if (this.language === 'en') {
                          item.targetLanguageName = lang.languageNameEn;
                        } else if (this.language === 'ru') {
                          item.targetLanguageName = lang.languageNameRu;
                        } else {
                          item.targetLanguageName = lang.languageNameEn;
                        }
                      }
                    });
                  });
                  this.detailData.NewTaskTargetLanguage = this.detailData.TaskTargetLanguage;
                }

                this.detailData.notSupportLangName = notSupportLangName.join(
                  this.$t('ctp.comma')
                );
                this.detailData.notSupportLangLength = notSupportLangName
                  ? notSupportLangName.length
                  : 0;
                this.detailData.targetLanguageArr = this.detailData
                  .targetLanguage
                  ? this.detailData.targetLanguage.split(',')
                  : [];
                this.detailDialogVisiable = true;
              }
            },
            err => {
              this.$alertMsg(
                this.$t('ctp.error.systemError'),
                this.$t('ctp.warning')
              );
              return err;
            }
          );
        } else {
          this.$alertMsg(
            `${this.$t('ctp.tips.payError.abnormal')}(${
              res.errorCode ? res.errorCode : res.ret.code
            })`,
            this.$t('ctp.warning')
          );
        }
        err => {
          this.$alertMsg(
            this.$t('ctp.error.systemError'),
            this.$t('ctp.warning')
          );
          return err;
        };
      });
    },
    // 下载原稿
    downloadfile(num, filePath) {
      if (num === 1) {
        if (this.detailData.sourceFilePath) {
          this.storageDownLoadFile(this.detailData.sourceFilePath);
        }
        // 下载译稿
      } else if (num === 2) {
        let reply = {};
        if (filePath) {
          this.storageDownLoadFile(filePath);
          this.deleteNotice();
          this.isRefresh = true;
        } else {
          const url = 'translation/v1/cp/replyList';
          AGC.callServiceAPI(
            url,
            { body: { taskId: this.detailData.taskId } },
            'POST',
            this.zoneIdData,
            3000,
            res => {
              if (res && res.data && res.data.length > 0) {
                this.data.forEach(el => {
                  if ((el.taskId = this.detailData.taskId)) {
                    el.taskReplyList = res.data;
                  }
                });
                reply = res.data[0];
                if (reply.filePath) {
                  this.storageDownLoadFile(reply.filePath);
                }
              } else {
                this.$promptMsg(this.$t('ctp.Unfinished'), this.$t('ctp.tips'));
              }
            },
            (err) => {
              this.$alertMsg(
                this.$t('ctp.error.systemError'),
                this.$t('ctp.warning')
              );
              return err;
            }
          );
        }
      }
    },
    // 关闭下载译稿弹窗是否刷新列表
    closeDialog() {
      if (this.isRefresh) {
        this.initData(1);
        this.isRefresh = false;
      }
    },
    // 用户下载完稿件，改状态为appraise，将消息删掉
    deleteNotice() {
      // 删除notice并改状态
      if (this.detailData.notice && this.detailData.notice.length > 0) {
        const body0 = {
          noticeId: this.detailData.notice[0].id,
          taskId: this.detailData.taskId
        };
        const url = 'translation/v1/cp/deleteNotice';
        AGC.callServiceAPI(url, { body: body0 }, 'POST', this.zoneIdData, 3000);
      } else {
        const body1 = { noticeId: '', taskId: this.detailData.taskId };
        const url = 'translation/v1/cp/deleteNotice';
        AGC.callServiceAPI(url, { body: body1 }, 'POST', this.zoneIdData, 3000);
      }
    },
    storageDownLoadFile(path) {
      if (path && path.trim().length > 0) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storageRef.child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    enLangTocnLang(val, num) {
      if (num === 1) {
        let langData = '';
        this.languages.forEach(el => {
          if (el.languageId === val) {
            langData = el.languageNameCh;
          }
        });
        return langData;
      } else if (num === 2) {
        const list = [];
        this.languages.forEach(el => {
          val.forEach(valData => {
            if (el.languageId === valData) {
              list.push(el.languageNameCh);
            }
          });
        });
        return list;
      }
    }
  }
};
</script>

<style scoped>
.elform{
  width: 100%;
  text-align: left;
}
.form-div /deep/ .el-form-item__label {
  width: 190px !important;
  padding-right: 20px;
}
div /deep/ .el-collapse-item__header {
  background: #f7f7f7;
  padding: 0 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 16px;
}
div /deep/ #viewTable th > .cell:before {
  border: none !important;
}
div /deep/ #viewTable .el-table__body-wrapper {
  max-height: none !important;
}
div /deep/ #viewTable td {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
div /deep/ #viewTable .cell {
  padding-left: 32px;
  padding-right: 32px;
}
#selectSuppliers {
  max-width: 300px;
}
div /deep/ #customer-table .el-table--border td:first-child .cell {
  padding: 0 8px 0 20px;
}
.btn-hover:hover {
  border-bottom: 1px solid;
}
.content-hidden {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clear {
  clear: both;
}
.delete-dialog {
  text-align: center;
}
.pay-ul {
  list-style-type: none;
  padding: 0;
}
.pay-ul li {
  line-height: 20px;
  margin-bottom: 20px;
}
.taskReplyList-div {
  line-height: 20px;
  margin-bottom: 4px;
}
.taskReplyList {
  display: inline-block;
}

.pay-money-li {
  margin-right: 20px;
  width: 180px;
  text-align: right;
  vertical-align: top;
}
.pay-dialog {
  text-align: center;
}

.cancel {
  padding-top: 24px;
  text-align: center;
}
el-link {
  text-decoration: none;
}

.sourceFileName {
  display: inline-block;
  width: 130px;
  margin-right: 20px;
  text-align: right;
  vertical-align: top;
}
.detail-dialog-div {
  line-height: 20px;
  margin-bottom: 20px;
}
.orderlist-wrapper {
  margin-top: 12px;
  padding: 28px 40px;
  background: #fff;
  min-height: 770px;
  border-radius: 8px;
}
.u-orderlist {
  margin-right: 12px;
  padding-top: 12px;
}
.el-pagination {
  margin-top: 28px;
}
.orderlist-process {
  margin-left: 10px;
  color: #007dff;
  cursor: pointer;
}
.order-detail-title {
  font-size: 16px;
  font-weight: 600;
}
.dialog-div-main {
  padding: 12px 0 36px 0;
  text-align: left;
  font-size: 14px;
}
.dialog-btn {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  background-color: #007dff;
}
.dialog-btn-middle {
  display: inline-block;
  width: 8px;
}
.orderlist-tr-nodata {
  text-align: center;
  color: #666;
  line-height: 48px;
}
.orderlist-detail {
  padding-bottom: 8px;
  border: 1px solid #007dff;
}
.order-operate-btn-middle {
  padding: 2px 0;
}
.table > tbody > tr > td {
  border-top: 0;
}
.supplier-mar-right {
  margin-right: 4px;
  cursor: pointer;
  font-size: 18px;
}
.appraise-top ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.appraise-top {
  padding-left: 24px;
}
.supplier-lang-table {
  width: 100%;
}
.supplier-lang-table tr th,
td {
  padding: 4px 0 4px 12px;
}
.supplier-lang-table-bottom {
  border-top: 1px solid rgb(167, 164, 164);
}
.dialog-download-div {
  color: #007dff;
  text-align: center;
}
.icon {
  cursor: pointer;
}
.qc-top {
  list-style: none;
  padding: 0;
  width: 1305px;
  float: left;
  margin-bottom: 0px;
}
.qc-top-input {
  width: 197px;
}
.qc-top-right {
  float: right;
}
.order-top-title {
  font-size: 18px;
  font-weight: 600;
}
.order-subtitle {
  color: #999;
}
.qc-top li {
  float: left;
  margin-bottom: 20px;
}
.order-nextstep {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #007dff;
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 8px;
}
.order-empty {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #fff;
  color: #999;
  outline: none;
  border: 1px solid #999;
  border-radius: 8px;
}
.order-detail-btn {
  padding: 4px 8px;
  font-size: 14px;
  background-color: #007dff;
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 4px;
}
.order-searchbtn {
  padding: 0 24px;
  font-size: 14px;
  background-color: #007dff;
  color: #fff;
  outline: none;
  border: 1px solid #007dff;
  line-height: 24px;
  border-radius: 12px;
}
.order-resetbtn {
  padding: 0 24px;
  font-size: 14px;
  background-color: #fff;
  color: #999;
  outline: none;
  line-height: 24px;
  border: 1px solid #999;
  border-radius: 12px;
}
.el-datepicker {
  min-width: 140px;
}
.paying-dialog {
  color: #191919;
  font-size: 16px;
  text-align: center;
}
.paying-dialog-timer {
  color: #666;
  font-size: 14px;
  text-align: center;
}
#customer-table .table > thead > tr > th {
  border-bottom: 0;
}
#customer-table .table > tbody > tr:last-child {
  border-bottom: 1px;
}
#customer-table .table > thead > tr > th:not(:first-child)::before {
  content: "";
  height: 8px;
  border-right: 1px solid #e2e2e2;
  margin-right: 8px;
}
#customer-table .table > thead:first-child > tr:first-child > th {
  border-top: 1px solid #e2e2e2;
}
.order-top-introduce {
  padding: 8px 8px 16px 16px;
  background-color: #fff;
}
.agc-icon {
  font-family: agc-icons !important;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.sort-caret {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  position: absolute;
  left: 7px;
}
.sort-caret.ascending {
  border-bottom-color: #c0c4cc;
  top: -13px;
}
.sort-caret.descending {
  border-top-color: #c0c4cc;
  bottom: -9px;
}
.ascending .sort-caret.ascending {
  border-bottom-color: #007dff;
}
.descending .sort-caret.descending {
  border-top-color: #007dff;
}
.agc-page-header__description {
  max-width: 1300px;
}
.search-width {
  width: 130px;
  display: inline-block;
  text-align: right;
  margin-right: 8px;
}
div /deep/.tooltip-title {
  overflow-y: auto !important;
  max-width: 30%;
  max-height: 20%;
}
div /deep/ .tip-title {
  overflow-y: auto !important;
  max-width: 25%;
  max-height: 15%;
}
/* 去掉表格单元格边框 */
div /deep/ #customer-table th {
  border: none !important;
}
#customer-table td,
#customer-table th.is-leaf {
  border: none !important;
}
/* 表格最外边框 */
div /deep/ #customer-table .el-table--border,
div /deep/ #customer-table .el-table--group {
  border: none !important;
}
/* 头部边框 */
div /deep/#customer-table thead tr th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  border-bottom: 1px solid #ebeef5 !important;
  border-right: none;
}
div /deep/#customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* 表格最外层边框-底部边框 */
div /deep/.el-table--border::after,
.el-table--group::after {
  width: none !important;
}
div /deep/.el-table th > .cell:before {
  display: none;
}
div /deep/ #customer-table th:not(:nth-last-of-type(2)) > .cell:after {
  content: "";
  border-right: 1px solid #e0e0e0;
  position: absolute;
  right: 10px;
  top: 5px;
  height: 12px;
}
.content-hidden {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div /deep/.el-table--striped td,
div /deep/.el-table--striped th.is-leaf {
  border-right-color: transparent;
}
.seatchBtn {
  min-width: 220px;
  margin-bottom: 20px;
}
.search-condition {
  display: flex;
  flex-wrap: wrap;
}
.search-flex {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-width: 650px !important;
}
.search-style {
  margin-right: 20px;
  margin-bottom: 20px;
}
.qrcode-vue{
  text-align: center;
}
.pay-tips{
  margin-top: 15px;
  font-weight: 600;
  font-size: 18px;
  color: #191919;
}
.pay-money{
  font-size: 16px;
  font-weight: 600;
  color: #191919;
  text-align: center;
}
.qr-time{
  width: 150px;
  display: inline-block;
}
#downloadQr{
  display: inline-block;
  padding: 20px 15px;
  background-color: #f7f7f7;
  width: 250px;
}
.qr-bottom{
  margin-bottom: 15px;
}
.pay-for{
  margin-right: 20px;
}
.pay-later{
  margin-left: 0!important;
}
.total-color{
font-size: 16px;
font-weight: 600;
color: #B92E29;
text-align: center;
}
.order-time {
  display: flex;
  padding: 10px 16px;
  background-color: #f7f7f7;
  word-break: break-word;
}
.expire-time-tips {
  margin-left: 5px;
}
.label-box{
  display: inline-block;
  width: 190px;
  text-align: right;
}
.label-box-text {
  padding-right: 20px;
}
.currencyTipsShow{
  color:#B92E29;
}
.tip-content {
  max-width: 600px;
}
</style>
