<template>
  <agc-dialog
    custom-class="dialog"
    width="80%"
    @close="close"
    :visible.sync="detailDialogVisiable"
    :title="$t('ctp.queryTask')"
  >
    <div>
      <ul class="detatil-ul">
        <!-- 基本信息 -->
        <li class="content-tittle" style="padding:0 0 9px 0">
          {{ $t("ctp.baseInfo") }}
        </li>
        <div class="bodybox">
          <!-- 任务单号 -->
          <li>
            <span>{{ $t("ctp.orderNum") + $t("ctp.colon") }}</span>
            <div class="list-content">
              {{ detailData.taskId || "--" }}
            </div>
          </li>
          <!-- 创建时间 -->
          <li>
            <span>{{ $t("ctp.creatTime") + $t("ctp.colon") }}</span>
            <div class="list-content">
              <span v-if="detailData.createTimeUTC">{{ Number(detailData.createTimeLocal)|formatDate }}</span>
              <span v-else>{{ detailData.createTime }}</span>
            </div>
          </li>
          <!-- 源语 -->
          <li>
            <span>
              <span class="order-color-red">*</span>
              {{ $t("ctp.originLanguage") + $t("ctp.colon") }}
            </span>
            <div class="list-content">
              {{ detailData.sourceLanguage || "--" }}
            </div>
          </li>
          <!-- 目标语 -->
          <li>
            <span>
              <span class="order-color-red">*</span>
              {{ $t("ctp.targetLanguage") + $t("ctp.colon") }}
            </span>
            <div class="list-content">
              <el-tooltip
                placement="bottom-start"
                effect="light"
                popper-class="tooltip-title"
                offset="1000"
                :disabled="detailData.targetLanguage.length < 30"
              >
                <div slot="content">
                  <div class="tip-content">
                    {{ detailData.targetLanguage }}
                  </div>
                </div>
                <span class="nowarp">{{ detailData.targetLanguage || "--" }}</span>
              </el-tooltip>
            </div>
          </li>
        </div>
        <li class="content-line" />
        <!-- 选择翻译内容 -->
        <li class="content-tittle">
          {{ $t("ctp.chooseContent") }}
        </li>
        <div class="bodybox">
          <!-- 应用名称 -->
          <div>
            <li>
              <span>
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.appName") }}</span>
              </span>
              <div class="list-content">
                {{ detailData.applicationName2 || "--" }}
              </div>
            </li>
            <!-- 应用介绍 -->
            <li>
              <span>
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.appIntroduction") }}</span>
              </span>
              <div class="list-content">
                <el-tooltip
                  placement="bottom-start"
                  effect="light"
                  popper-class="tooltip-title"
                  :disabled="detailData.appIntroduce2.length < 30"
                >
                  <div slot="content">
                    <div class="tip-content">
                      {{
                        detailData.appIntroduce2
                          ? detailData.appIntroduce2
                          : "--"
                      }}
                    </div>
                  </div>
                  <span class="nowarp">{{ detailData.appIntroduce2 || "--" }}</span>
                </el-tooltip>
              </div>
            </li>
            <!-- 应用一句话简介 -->
            <li>
              <span>
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.appshortIntroduct") }}</span>
              </span>
              <div class="list-content">
                <el-tooltip
                  placement="bottom-start"
                  effect="light"
                  popper-class="tooltip-title"
                  :disabled="detailData.appIntroduceInshort2.length < 30">
                  <div slot="content">
                    <div class="tip-content">
                      {{
                        detailData.appIntroduceInshort2
                          ? detailData.appIntroduceInshort2
                          : "--"
                      }}
                    </div>
                  </div>
                  <span class="nowarp">{{ detailData.appIntroduceInshort2 || "--" }}</span>
                </el-tooltip>
              </div>
            </li>
            <!-- 新版本特性 -->
            <li>
              <span>
                <span>{{ $t("ctp.feature") }}</span>
              </span>
              <div class="list-content">
                <el-tooltip
                  placement="bottom-start"
                  effect="light"
                  popper-class="tooltip-title"
                  :disabled="detailData.appFeatures2.length < 30"
                >
                  <div slot="content">
                    {{ detailData.appFeatures2 }}
                  </div>
                  <span class="nowarp">{{ detailData.appFeatures || "--" }}</span>
                </el-tooltip>
              </div>
            </li>
            <!-- 上传本地文件 -->
            <li>
              <span>
                <span class="order-color-red">*</span>
                <span>
                  {{ $t("ctp.uploadLocalFile") }}
                  <el-tooltip popper-class="tips-box" effect="light" placement="top-start">
                    <div slot="content">
                      {{ $t("ctp.fileFormat") }}
                      <br>
                      {{ $t("ctp.contentFormat") }}
                      <br>
                    </div>
                    <span id="icon-uploadTip">
                      <agc-icon name="question" type="static" />
                    </span>
                  </el-tooltip>
                  {{ $t("ctp.colon") }}
                </span>
              </span>
              <div class="list-content">
                <div>
                  <span v-for="(lang, index) in detailData.sourceFileName.split(',')" :key="index">
                    <span v-if="!detailData.sourceFileName">--</span>
                    <el-link
                      class="nowarp padding-right"
                      v-else
                      type="primary"
                      @click="handlePreviewSource(detailData.spTranslatedFileId.split(',')[index])"
                    >{{ lang }}</el-link>
                  </span>
                </div>
              </div>
            </li>
            <!-- 附件截图和说明 -->
            <li>
              <span>
                <span>{{ $t("ctp.screenshot") }}</span>
              </span>
              <div class="list-content">
                <div>
                  <span
                    v-for="(lang, index) in detailData.screenshotFileName.split(',')"
                    :key="index"
                  >
                    <span v-if="!detailData.screenshotFileName">--</span>
                    <el-link
                      class="nowarp padding-right"
                      v-else
                      type="primary"
                      @click="handlePreviewScreen(detailData.spScreenshotFileId.split(',')[index])"
                    >{{ lang }}</el-link>
                  </span>
                </div>
              </div>
            </li>
            <!-- 重复使用之前 -->
            <li>
              <span>
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.Reuse") + $t("ctp.colon") }}</span>
              </span>
              <div class="list-content">
                {{
                  detailData.repeatUse === "true" ? $t("ctp.yes") : $t("ctp.no")
                }}
              </div>
            </li>
            <!-- 是否愿意 -->
            <li>
              <span>
                <span class="order-color-red">*</span>
                <span>{{ $t("ctp.contributePlatform") + $t("ctp.colon") }}</span>
              </span>
              <div class="list-content">
                {{
                  detailData.platformUse === "true"
                    ? $t("ctp.yes")
                    : $t("ctp.no")
                }}
              </div>
            </li>
          </div>
        </div>
        <li class="content-line" />
        <!-- 选择服务供应商 -->
        <li class="content-tittle">
          {{ $t("ctp.selectSupplier") }}
        </li>
        <div class="bodybox">
          <!-- 供应商 -->
          <li>
            <span>{{ $t("ctp.supplier") }}{{ $t("ctp.colon") }}</span>
            <div>
              <span style="margin-right:30px">{{ detailData.spName }}</span>
              <el-link
                type="primary"
                target="_blank"
                :href="webLink"
                v-if="detailData.spName !== '--'"
              >
                {{ $t("ctp.description") }}
              </el-link>
            </div>
          </li>
          <!-- 您的联系方式 -->
          <li>
            <span>
              <span class="order-color-red">*</span>
              <span>{{ $t("ctp.developerInformation") }}{{ $t("ctp.colon") }}</span>
            </span>
            <div class="list-content">
              {{ detailData.devEmail || "--" }}
            </div>
          </li>
          <!-- 期望交付时间 -->
          <li>
            <span>{{ $t("ctp.expectedTime") }}{{ $t("ctp.colon") }}</span>
            <div class="list-content">
              <span v-show="detailData.expectDeliveryTimeUTC">
                {{ Number(detailData.expectDeliveryTimeLocal) | formatDate }}
              </span>
              <span v-show="!detailData.expectDeliveryTimeUTC && detailData.expectDeliveryTime">
                {{
                  detailData.expectDeliveryTime
                    ? detailData.expectDeliveryTime.length > 11
                      ? detailData.expectDeliveryTime
                      : detailData.expectDeliveryTime + " 00:00:00"
                    : "--"
                }}
              </span>
              <span v-show="!detailData.expectDeliveryTimeUTC && !detailData.expectDeliveryTime">
                {{ detailData.expectDeliveryDay ? detailData.expectDeliveryDay : "--" }}</span>
            </div>
          </li>
        </div>
        <li class="content-line" />
        <!-- 确认订单并支付 -->
        <li class="content-tittle">
          {{ $t("ctp.orderTittle") }}
        </li>
      </ul>
    </div>
    <div class="bodybox">
      <table class="dialog-table">
        <thead>
          <tr>
            <th>{{ $t("ctp.translationContent") }}</th>
            <th>{{ $t("ctp.originLanguage") }}</th>
            <th>{{ $t("ctp.targetLanguage") }}</th>
            <th>{{ $t('ctp.startingPrice', {currency: detailData.currency}) }}</th>
            <th>{{ $t("ctp.singWordUnitPrice", {currency: detailData.currency}) }}</th>
            <th>
              {{ $t("ctp.totalWords") }}
              <el-tooltip
                class="item"
                effect="light"
                placement="top-start"
                popper-class="tooltip-title"
              >
                <div slot="content">
                  {{ $t("ctp.tips.totalWords") }}
                </div>
                <agc-icon name="question" color="#c5c5c5" size="18" />
              </el-tooltip>
            </th>
            <th>{{ $t("ctp.singleLangTotal", {currency: detailData.currency}) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="
              newTaskLength.length < 1 &&
                ((!detailData.applicationName ? false : true) ||
                  (!detailData.appIntroduce ? false : true) ||
                  (!detailData.appIntroduceInshort ? false : true) ||
                  (!detailData.appFeatures ? false : true) ||
                  (!detailData.originalPath ? false : true) ||
                  (!detailData.sourceLanguage ? false : true)) &&
                (detailData.targetLanguageArr
                  ? detailData.targetLanguageArr.length === 0
                  : false)
            "
          >
            <td>
              <el-tooltip
                placement="bottom-start"
                effect="light"
                popper-class="tooltip-title"
                :disabled="detailData.showTitle.length < 30"
              >
                <div slot="content">
                  {{ detailData.showTitle ? detailData.showTitle : "--" }}
                </div>
                <span
                  v-if="
                    detailData.application && detailData.application === 'true'
                  "
                >
                  {{
                    (
                      detailData.applicationName +
                      "-" +
                      detailData.appIntroduce +
                      "-" +
                      detailData.appIntroduceInshort +
                      "-" +
                      detailData.appFeatures
                    ).length > 0
                      ? (
                        detailData.applicationName +
                        "-" +
                        detailData.appIntroduce +
                        "-" +
                        detailData.appIntroduceInshort +
                        "-" +
                        detailData.appFeatures
                      ).length > 15
                        ? detailData.showTitle.substring(0, 15) + "..."
                        : detailData.showTitle
                      : "--"
                  }}
                  <br>
                </span>
              </el-tooltip>
              <span
                v-if="detailData.sourceFileName && detailData.sourceFileName !== ''"
                :title="detailData.sourceFileName"
              >
                {{
                  detailData.sourceFileName
                    ? detailData.sourceFileName.length > 15
                      ? detailData.sourceFileName.substring(0, 15) + "..."
                      : detailData.sourceFileName
                    : "--"
                }}
              </span>
              <span
                v-show="
                  detailData.application === 'false' &&
                    !detailData.sourceFileName
                "
              >--</span>
            </td>
            <td>{{ detailData.sourceLanguage }}</td>
            <td
              colspan="5"
              v-show="detailData.status === 'init'"
              class="orderlist-tr-nodata"
            >
              {{ $t("ctp.noRelevantData") }}
            </td>
          </tr>

          <tr
            v-for="(lang, index) in detailData.NewTaskTargetLanguage"
            :key="lang.taskId"
            v-show="newTaskLength.length"
          >
            <td :rowspan="newTaskLength.length" v-if="index === 0">
              <el-tooltip
                placement="bottom-start"
                effect="light"
                popper-class="tooltip-title"
                :disabled="detailData.showTitle.length < 30"
              >
                <div slot="content">
                  {{ detailData.showTitle ? detailData.showTitle : "--" }}
                </div>
                <span
                  v-if="
                    detailData.application && detailData.application === 'true'
                  "
                >
                  {{
                    (
                      detailData.applicationName +
                      "-" +
                      detailData.appIntroduce +
                      "-" +
                      detailData.appIntroduceInshort +
                      "-" +
                      detailData.appFeatures
                    ).length > 0
                      ? (
                        detailData.applicationName +
                        "-" +
                        detailData.appIntroduce +
                        "-" +
                        detailData.appIntroduceInshort +
                        "-" +
                        detailData.appFeatures
                      ).length > 15
                        ? detailData.showTitle.substring(0, 15) + "..."
                        : detailData.showTitle
                      : "--"
                  }}
                  <br>
                </span>
              </el-tooltip>
              <span
                v-if="detailData.sourceFileName && detailData.sourceFileName !== ''"
                :title="detailData.sourceFileName"
              >
                {{
                  detailData.sourceFileName
                    ? detailData.sourceFileName.length > 15
                      ? detailData.sourceFileName.substring(0, 15) + "..."
                      : detailData.sourceFileName
                    : "--"
                }}
              </span>
              <span
                v-show="
                  detailData.application === 'false' &&
                    !detailData.sourceFileName
                "
              >--</span>
            </td>
            <td :rowspan="newTaskLength.length" v-if="index === 0">
              {{ detailData.sourceLanguage }}
            </td>
            <td>{{ lang.targetLanguageName }}</td>
            <td v-show="detailData.status !== 'init'">
              <span v-if="lang.prePrice === '-1.0'">--</span>
              <span v-else>{{ lang.prePrice ? Number(lang.prePrice).toFixed(2)
                : Number(lang.prePrice).toFixed(2) }}</span>
            </td>
            <td v-show="detailData.status !== 'init'">
              <span>{{ lang.preCost ? Number(lang.preCost).toFixed(2) : "--" }}</span>
            </td>
            <td v-show="detailData.status !== 'init'">
              <span>{{ lang.totalWords ? Math.ceil(Number(lang.totalWords)) : "--" }}</span>
            </td>

            <td v-show="detailData.status !== 'init'">
              <span>
                {{
                  lang.originalCost
                    ? Number(lang.originalCost).toFixed(2)
                    : "--"
                }}
              </span>
            </td>
            <td
              colspan="4"
              v-show="detailData.status === 'init'"
              :rowspan="newTaskLength.length"
              v-if="index === 0"
              class="orderlist-tr-nodata"
            >
              {{ $t("ctp.noRelevantData") }}
            </td>
          </tr>

          <tr
            v-for="(lang, index) in detailData.targetLanguageArr"
            :key="index"
            v-show="
              !newTaskLength.length &&
                (detailData.applicationName ||
                  detailData.appIntroduce ||
                  detailData.appIntroduceInshort ||
                  detailData.originalPath ||
                  detailData.sourceLanguage ||
                  detailData.targetLanguageArr)
            "
          >
            <td :rowspan="detailData.targetLanguageArr.length" v-if="index === 0">
              <el-tooltip
                placement="bottom-start"
                effect="light"
                popper-class="tooltip-title"
                :disabled="detailData.targetLanguageArr.length < 20"
              >
                <div slot="content">
                  {{ detailData.showTitle ? detailData.showTitle : "--" }}
                </div>
                <span
                  v-if="
                    detailData.application && detailData.application === 'true'
                  "
                >
                  {{
                    (
                      detailData.applicationName +
                      "-" +
                      detailData.appIntroduce +
                      "-" +
                      detailData.appIntroduceInshort +
                      "-" +
                      detailData.appFeatures
                    ).length > 0
                      ? (
                        detailData.applicationName +
                        "-" +
                        detailData.appIntroduce +
                        "-" +
                        detailData.appIntroduceInshort +
                        "-" +
                        detailData.appFeatures
                      ).length > 15
                        ? detailData.showTitle.substring(0, 15) + "..."
                        : detailData.showTitle
                      : "--"
                  }}
                  <br>
                </span>
              </el-tooltip>
              <span
                v-if="detailData.sourceFileName && detailData.sourceFileName !== ''"
                :title="detailData.sourceFileName"
              >
                {{
                  detailData.sourceFileName
                    ? detailData.sourceFileName.length > 15
                      ? detailData.sourceFileName.substring(0, 15) + "..."
                      : detailData.sourceFileName
                    : "--"
                }}
              </span>
              <span
                v-show="
                  detailData.application === 'false' &&
                    detailData.sourceFileName === ''
                "
              >--</span>
            </td>
            <td
              :rowspan="detailData.targetLanguageArr.length"
              v-if="index === 0"
            >
              {{ detailData.sourceLanguage }}
            </td>
            <td>{{ lang }}</td>
            <td
              colspan="4"
              v-show="detailData.status === 'init'"
              :rowspan="detailData.targetLanguageArr.length"
              v-if="index === 0"
              class="orderlist-tr-nodata"
            >
              {{ $t("ctp.noRelevantData") }}
            </td>
          </tr>

          <tr
            class="noTargetLan"
            v-if="
              detailData.notSupportLangLength > 0 && newTaskLength.length > 0
            "
          >
            <td :colspan="7" class="noTarget">
              <span>
                <span>{{ $t("ctp.tips.notSPSupportedLang", {num: detailData.notSupportLangLength}) }}</span>
                <br>
                <span>{{ detailData.notSupportLangName }}</span>
              </span>
            </td>
          </tr>
          <tr class="priceTable" v-if="detailData.status !== 'init'">
            <td :colspan="7">
              <div class="float-rt">
                <!-- 总价 -->
                <div class="orderPrice">
                  {{
                    detailData.originalCost === "-1.0"
                      ? detailData.allPay
                      : Number(detailData.originalCost).toFixed(2)
                  }}
                </div>
                <!-- 折扣优惠 -->
                <div
                  class="discountedPrice"
                  v-if="
                    detailData.discount && Number(detailData.discount) !== -1
                  "
                >
                  -{{ Number(detailData.discount).toFixed(2) }}
                </div>
                <div class="discountedPrice" v-else>
                  -{{
                    detailData.originalCost === "-1.0"
                      ? (
                        detailData.allPay - Number(detailData.cost).toFixed(2)
                      ).toFixed(2)
                      : (Number(detailData.originalCost) - Number(detailData.cost)).toFixed(2)
                  }}
                </div>
                <!-- 满减券 -->
                <div class="reward">
                  -{{ couponsMoney }}
                </div>
                <!-- 代金券 -->
                <div class="reward">
                  -{{
                    rewardPrice
                  }}
                </div>
                <!-- 实际付款 -->
                <div class="totalPrice">
                  {{
                    detailData.cost ? Number(detailData.cost).toFixed(2) : "--"
                  }}
                </div>
              </div>
              <div class="float-rt">
                <!-- 总价 -->
                <div
                  class="priceLabel"
                >
                  {{ $t("ctp.totalPrice", {currency: detailData.currency}) }}{{ $t("ctp.colon") }}
                </div>
                <!-- 活动优惠 -->
                <div class="priceLabel">
                  {{ $t("ctp.preference", {currency: detailData.currency}) }}
                  <el-tooltip class="item" effect="light" placement="bottom-start">
                    <div slot="content">
                      {{ $t("ctp.tips.preference") }}
                    </div>
                    <agc-icon name="question" color="#c5c5c5" size="18" />
                  </el-tooltip>
                  {{ $t("ctp.colon") }}
                </div>
                <!-- 满减券 -->
                <div class="priceLabel">
                  {{ $t("ctp.rewardsCoupon", {currency: detailData.currency}) }}
                  <el-tooltip class="item" effect="light" placement="bottom-end">
                    <div slot="content">
                      {{ $t("ctp.couponsTips") }}
                    </div>
                    <agc-icon name="question" color="#c5c5c5" size="18" />
                  </el-tooltip>
                  {{ $t("ctp.colon") }}
                </div>
                <!-- 代金券 -->
                <div class="priceLabel">
                  {{ $t("ctp.vouchers", {currency: detailData.currency}) }}
                  <el-tooltip class="item" effect="light" placement="bottom-end">
                    <div slot="content">
                      {{ $t("ctp.vouchersTips") }}
                    </div>
                    <agc-icon name="question" color="#c5c5c5" size="18" />
                  </el-tooltip>
                  {{ $t("ctp.colon") }}
                </div>
                <!-- 实际付款 -->
                <div
                  class="priceLabel"
                >
                  {{ $t("ctp.actualPrice", {currency: detailData.currency}) }}{{ $t("ctp.colon") }}
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="
              newTaskLength.length < 1 &&
                !(
                  (!detailData.applicationName ? false : true) ||
                  (!detailData.appIntroduce ? false : true) ||
                  (!detailData.appIntroduceInshort ? false : true) ||
                  (!detailData.appFeatures ? false : true) ||
                  (!detailData.originalPath ? false : true) ||
                  (!detailData.sourceLanguage ? false : true) ||
                  (detailData.targetLanguageArr
                    ? detailData.targetLanguageArr.length !== 0
                    : false)
                )
            "
            class="tr-main"
          >
            <td colspan="7" class="orderlist-tr-nodata">
              {{ $t("ctp.noRelevantData") }}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <ul>
          <!-- 任务单备注 -->
          <li>
            <span>{{ $t("ctp.orderRemarks") }}{{ $t("ctp.colon") }}</span>
            <div class="list-content">
              <el-tooltip
                placement="bottom-start"
                effect="light"
                popper-class="tooltip-title"
                :disabled="detailData.remarksForSp.length < 30"
              >
                <div slot="content">
                  {{ detailData.remarksForSp ? detailData.remarksForSp : "--" }}
                </div>
                <span class="nowarp">{{ detailData.remarksForSp ? detailData.remarksForSp : "--" }}</span>
              </el-tooltip>
            </div>
          </li>
          <!-- 任务单状态 -->
          <li>
            <span>{{ $t("ctp.taskStatus") }}{{ $t("ctp.colon") }}</span>
            <div class="list-content">
              {{ status[detailData.status] }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="middle-line" />
    <span slot="footer" class="footer-button">
      <el-button @click="close" id="close-search-modal">{{ $t("ctp.close") }}</el-button>
    </span>
  </agc-dialog>
</template>

<script>
import { timestampToDate } from '../../util/common.js';
import { VOUCHER_TYPE, COUPON_TYPE } from '../../constant/index.js';
import { floatAdd, floatToFixed } from '../../util/priceProcessing.js';
export default {
  name: 'CpDetailDialog',
  // 通过局部过滤器来实现
  filters: {
    formatDate(value) {
      if (!value) return '';
      return timestampToDate(value);
    }
  },
  props: {
    detailDialogVisiable: {
      type: Boolean,
      default: false
    },
    detailData: {
      type: Object,
      default: () => {}
    },
    newTaskLength: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      qcDialogVisible: false,
      QCfrom: 'user',
      remarks: '',
      qcUploadPath: '',
      notice: [],
      status: {
        init: this.$t('ctp.init'),
        nopay: this.$t('ctp.nopay'),
        pay: this.$t('ctp.pay'),
        translate: this.$t('ctp.pay'),
        overtime: this.$t('ctp.overtime'),
        completed: this.$t('ctp.completed'),
        refresh: this.$t('ctp.upDated'),
        appraise: '待评价',
        end: this.$t('ctp.end'),
        cancel: this.$t('ctp.expired')
      },
      payStatus: {
        init: this.$t('ctp.nopay'),
        nopay: this.$t('ctp.nopay'),
        pay: this.$t('ctp.pay'),
        translate: this.$t('ctp.pay'),
        overtime: this.$t('ctp.pay'),
        completed: this.$t('ctp.pay'),
        refresh: this.$t('ctp.pay'),
        appraise: '已支付',
        end: this.$t('ctp.end'),
        cancel: this.$t('ctp.expired')
      },
      zoneIdData: '',
      webLink: ''
    };
  },
  computed: {
    // 代金券
    rewardPrice() {
      let amount = 0;
      if (this.detailData && this.detailData.deductedRewardInfos) {
        JSON.parse(this.detailData.deductedRewardInfos).forEach(item => {
          if (item.type === VOUCHER_TYPE) {
            amount = floatAdd(parseFloat(amount), item.deductedAmount);
          }
        });
        return floatToFixed(amount);
      }
      if (this.detailData && this.detailData.reward) {
        return floatToFixed(this.detailData.reward);
      }
      return '0.00';
    },
    couponsMoney() {
      let amount = 0;
      if (this.detailData && this.detailData.deductedRewardInfos) {
        JSON.parse(this.detailData.deductedRewardInfos).forEach(item => {
          if (item.type === COUPON_TYPE) {
            amount = floatAdd(parseFloat(amount), item.deductedAmount);
          }
        });
      }
      return floatToFixed(amount) || '0.00';
    }
  },
  watch: {
    detailData(val) {
      this.detailData = val;
      this.getSupplier();
    }
  },
  mounted() {
    this.zoneIdData = sessionStorage.getItem('tszoneId');
  },
  methods: {
    // 用户下载完稿件，改状态为appraise，将消息删掉
    deleteNotice() {
      if (this.detailData.notice && this.detailData.notice.length > 0) {
        const body8 = {
          noticeId: this.detailData.notice[0].id,
          taskId: this.detailData.taskId
        };
        const url = 'translation/v1/cp/deleteNotice';
        AGC.callServiceAPI(url, { body: body8 }, 'POST', this.zoneIdData, 3000, () => {});
      } else {
        const body1 = { noticeId: '', taskId: this.detailData.taskId };
        const url = 'translation/v1/cp/deleteNotice';
        AGC.callServiceAPI(url, { body: body1 }, 'POST', this.zoneIdData, 3000, () => {});
      }
    },
    // 下载译稿
    downloadReply() {
      this.$emit('openDownloadDialog', true);
    },
    // 下载历史回稿
    downloadHistoryReply(path, id) {
      if (path && path.length > 0) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const filePath = path;
        const childPath =
          filePath.indexOf(':') > -1
            ? filePath.substring(filePath.indexOf(':') + 1)
            : filePath;
        const child = storageRef.child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
          if (this.detailData.taskReplyList[0].id === id) {
            this.deleteNotice();
          }
        });
      } else {
        this.$promptMsg(this.$t('ctp.noTranslated'), this.$t('ctp.tips'));
      }
    },
    close() {
      this.detailDialogVisiable = false;
      this.$emit('changeVisiable', false);
    },
    // 下载
    handlePreviewSource(sourceFilePath) {
      if (sourceFilePath) {
        const storage = this.$agc.storage();
        const path = sourceFilePath;
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storage.storageReference().child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    handlePreviewScreen(screenshotFilePath) {
      if (screenshotFilePath) {
        const storage = this.$agc.storage();
        const path = screenshotFilePath;
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storage.storageReference().child(childPath);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    // 获取供应商
    getSupplier() {
      const taskId = sessionStorage.getItem('agc_translate_skip_taskId');
      const body = { spId: '', taskId: '' };
      if (taskId && taskId !== undefined) {
        body.taskId = taskId;
      }
      const url = 'translation/v1/cp/getServiceProvider';
      AGC.callServiceAPI(
        url,
        { header: {}, body },
        'POST',
        this.zoneIdData,
        7000,
        res => {
          if (res.serviceProviders && res.serviceProviders.length > 0) {
            res.serviceProviders.forEach(e => {
              if (e.spId === this.detailData.spId) {
                this.webLink = e.webSite;
              }
            });
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.padding-right {
  padding-right: 20px;
}
.tr-main {
  vertical-align: middle !important;
}
div /deep/ .el-dialog__body {
  padding: 18px 20px 0;
  max-height: 400px;
  overflow-y: auto;
}
div /deep/ .el-dialog__footer {
  padding: 0px 20px 18px;
  height: 71px;
}
.bodybox {
  margin: 0 80px;
}
.tips-box {
  width: 400px;
  word-break: break-word;
}
#icon-uploadTip .agc-icon--static {
  display: inline-block;
  vertical-align: middle;
}
.footer-button {
  display: inline-block;
  position: relative;
  height: 35px;
  margin-top: 18px;
}
.dialog-empty {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #fff;
  color: #999;
  outline: none;
  border: 1px solid #999;
  border-radius: 8px;
}
#sp-li {
  padding-bottom: 4px;
}
.taskReplyList-span {
  vertical-align: top;
}
.taskReplyList-div {
  line-height: 20px;
  margin-bottom: 4px;
  color: #666;
}
.cancel {
  text-align: center;
}
.taskReplyList {
  display: inline-block;
}
.dialog-up-step {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #169bd5;
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 4px;
}
.dialog table.dialog-table tr th,
td {
  padding: 12px 0;
  border: 1px solid #e2e2e2;
  text-align: center;
}
.dialog table.dialog-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  margin: 9px 0;
}
ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px;
}
ul li {
  padding: 9px 0;
  display: flex;
}
th {
  font-weight: 900;
  color: #191919;
}
td {
  color: #666;
}
ul li > span:first-child {
  display: inline-block;
  width: 350px;
  font-size: 14px;
  text-align: right;
  margin-right: 8px;
}
.notice-color span {
  color: #191919;
}
.reply-fileName {
  text-decoration: none;
}
.middle-line {
  clear: both;
  margin-top: 9px;
  border-top: 1px solid #e2e2e2;
}
.upload-download-operation {
  float: right;
  padding: 10px 30px 0 0;
}
.float-rt {
  float: right;
  line-height: 28px;
  text-align: right;
  padding-right: 10px;
}
.priceTable {
  background-color: #f7f7f7;
  height: 117px;
}
.totalPrice {
  color: #fa2a2d;
  font-size: 20px;
}

.content-tittle {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  color: #191919;
  font-family: Microsoft Yahei, sans-serif;
}
.content-line {
  border-top: 1px solid #e0e0e0;
  margin: 9px 0;
  padding: 0 !important;
}
.order-color-red {
  display: inline-block;
  margin-right: 4px;
  color: red;
}
.list-content {
  width: 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.tooltip-title {
  max-width: 30%;
}
.noTargetLan {
  height: 52px;
  color: #666666;
  text-align: left !important;
  word-break: break-all;
}
.noTargetLan .noTarget {
  padding-left: 10px;
  text-align: left !important;
}
.nowarp {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip-title {
  max-width: 400px;
}
</style>

<style>
#close-search-modal {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
