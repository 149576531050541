<template>
  <div class="price-result">
    <el-form label-width="auto" class="elform">
      <!-- 总价 -->
      <div class="label-item">
        <el-form-item
          :label="$t('ctp.totalPrice', {currency: detailData.currency}) + $t('ctp.colon')"
        >
          <span>
            {{ totalPrice }}
          </span>
        </el-form-item>
      </div>
      <!-- 折扣优惠 -->
      <div class="label-item">
        <el-form-item>
          <span slot="label">
            {{ $t("ctp.preference", {currency: detailData.currency}) }}
            <el-tooltip popper-class="tips-box" effect="light" placement="top-start">
              <div slot="content">
                {{ $t("ctp.tips.preference") }}
              </div>
              <span>
                <agc-icon name="question" color="#c5c5c5" size="18" />
              </span>
            </el-tooltip>
            {{ $t("ctp.colon") }}
          </span>
          <span>
            -{{ discountPrice }}
          </span>
        </el-form-item>
      </div>
      <!-- 满减券 -->
      <div class="label-item">
        <el-form-item>
          <span slot="label">
            {{ $t("ctp.rewardsCoupon", {currency: detailData.currency}) }}
            <el-tooltip popper-class="tips-box" effect="light" placement="top-start">
              <div slot="content">
                {{ $t("ctp.couponsTips") }}
              </div>
              <span>
                <agc-icon name="question" color="#c5c5c5" size="18" />
              </span>
            </el-tooltip>
            {{ $t("ctp.colon") }}
          </span>
          <span>
            -{{ couponsPrice.remainingAmount }}
          </span>
        </el-form-item>
        <div class="couponsMoney-div">
          <el-tooltip class="item" popper-class="tips-box" effect="light" placement="bottom-end" :disabled="selfCouponList.length > 0">
            <div slot="content">
              {{ $t('ctp.noAvailable') }}
            </div>
            <i class="el-icon-arrow-right" :class="selfCouponList.length > 0 ? 'arrow-right' : 'notclick'" @click="openCouponsDialog" />
          </el-tooltip>
        </div>
      </div>
      <!-- 代金券 -->
      <div class="label-item">
        <el-form-item>
          <span slot="label">
            {{ $t("ctp.vouchers", {currency: detailData.currency}) }}
            <el-tooltip popper-class="tips-box" effect="light" placement="top-start">
              <div slot="content">
                {{ $t("ctp.vouchersTips") }}
              </div>
              <span>
                <agc-icon name="question" color="#c5c5c5" size="18" />
              </span>
            </el-tooltip>
            {{ $t("ctp.colon") }}
          </span>
          <span>
            -{{ rewardPrice }}
          </span>
        </el-form-item>
        <div class="vouchersMoney-div">
          <el-tooltip class="item" popper-class="tips-box" effect="light" placement="bottom-end" :disabled="selfVouchersList.length > 0">
            <div slot="content">
              {{ $t('ctp.noAvailable') }}
            </div>
            <i class="el-icon-arrow-right" :class="selfVouchersList.length > 0 ? 'arrow-right' : 'notclick'" @click="openVouchersDialog" />
          </el-tooltip>
        </div>
      </div>
      <!-- 实际付款 -->
      <div class="label-item">
        <el-form-item
          :label="$t('ctp.actualPrice', {currency: detailData.currency}) + $t('ctp.colon')"
        >
          <span>
            {{ totalCost }}
          </span>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { floatToFixed, floatAdd, floatSub } from '../../util/priceProcessing.js';
export default {
  name: 'PriceResult',
  props: {
    priceInfo: { type: Object, default: () => {} },
    availableCouponList: { type: Array, default: () => [] },
    vouchersList: { type: Array, default: () => [] }
  },
  data() {
    return {
      selfVouchersList: this.vouchersList,
      selfCouponList: this.availableCouponList,
      detailData: this.priceInfo
    };
  },
  computed: {
    // 总价
    totalPrice() {
      return floatToFixed(this.detailData.order);
    },
    // 折扣优惠
    discountPrice() {
      if (floatToFixed(this.detailData.discount) !== -1) {
        return floatToFixed(this.detailData.discount);
      }
      return '0.00';
    },
    // 满减券
    couponsPrice() {
      return this.selfCouponList.find(item =>
        item.checked === true
      ) || { remainingAmount: '0.00' };
    },
    // 代金券
    rewardPrice() {
      let amount = 0;
      this.selfVouchersList.map(item => {
        if (item.checked) {
          amount = floatAdd(parseFloat(amount), item.remainingAmount);
        }
      });
      if (this.totalPrice - this.discountPrice - this.couponsPrice.remainingAmount < 0) {
        amount = 0;
      } else if (amount > this.totalPrice - this.discountPrice - this.couponsPrice.remainingAmount) {
        amount = floatSub(floatSub(
          floatToFixed(this.totalPrice),
          floatToFixed(this.discountPrice),
        ), floatToFixed(this.couponsPrice.remainingAmount));
      }
      return floatToFixed(amount);
    },
    // 实际付款
    totalCost() {
      const total = floatToFixed(floatSub(floatSub(floatSub(this.totalPrice, this.discountPrice),
                                                   this.rewardPrice)
                                          , this.couponsPrice.remainingAmount));
      this.$emit('obtainTotal', total);
      return total;
    }
  },
  watch: {
    vouchersList(newVal) {
      this.selfVouchersList = newVal;
    },
    availableCouponList(newVal) {
      this.selfCouponList = newVal;
    },
    priceInfo(newVal) {
      this.detailData = newVal;
    }
  },
  mounted() {

  },
  methods: {
    openVouchersDialog() {
      this.$emit('openVouchersDialog');
    },
    openCouponsDialog() {
      this.$emit('openCouponsDialog');
    }

  }
};
</script>

<style lang="scss" scoped>
  .elform{
    width: 100%;
    text-align: left;
    /deep/ .el-form-item__label-wrap {
      margin-left: 0 !important;
    }
    /deep/ .el-form-item__label {
      width: 190px !important;
    }
  }
  .price-result {
    display: flex;
    flex-direction: row;
    text-align: right;
    line-height: 28px;
    .price-label {
      flex: 1;
    }
    .price-value {
      padding: 0 10px;
    }
    .total-price {
      color: #fa2a2d;
      font-size: 20px;
    }
    .label-item {
      margin-bottom: 20px;
      position: relative;
    }
    .arrow-right{
      cursor: pointer;
    }
    .notclick{
      cursor: not-allowed;
    }
    .vouchersMoney-div,.couponsMoney-div{
      position: absolute;
      right:200px;
      top:4px
    }
  }
</style>
